import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popup from "components/Popup";
import Restricted from "components/Restricted";
import { Interweave } from "interweave";
import { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { EditableContent } from "Types";

const EditableContentGadget = ({
    errorMessage,
    data,
    save,
}: {
    errorMessage: string;
    data: EditableContent;
    save: (d: string) => void;
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showError, setShowError] = useState(true);
    const [editorData, setEditorData] = useState("");

    const toggleEditPanel = () => {
        if (isOpen) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    return (
        <>
            <div>
                <Restricted to="gadgets.modify">
                    <>
                        <FontAwesomeIcon
                            className="edit-icon"
                            icon="pencil"
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleEditPanel();
                            }}
                        />
                        <Popup
                            header="Edit content"
                            isOpen={isOpen}
                            toggle={toggleEditPanel}
                            enforceFocus={false}
                        >
                            {errorMessage && showError && (
                                <Alert
                                    onClose={() => setShowError(false)}
                                    dismissible
                                >
                                    {errorMessage}
                                </Alert>
                            )}
                            <CKEditor
                                editor={ClassicEditor}
                                data={data ? data.content : ""}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEditorData(data);
                                }}
                            />
                            <Button
                                className="ck-editor-save-button"
                                onClick={() => save(editorData)}
                            >
                                Save
                            </Button>
                        </Popup>
                    </>
                </Restricted>
            </div>
            <Interweave content={data ? data.content : ""} />
            {!data && <br />}
        </>
    );
};

export default EditableContentGadget;
