import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Layout from "components/Layout";
import Index from "pages/Index";
import Login from "pages/Login";
import Profile from "pages/profile/Profile";
import ModPassword from "pages/profile/ModPassword";
import ModEmail from "pages/profile/ModEmail";
import UserList from "pages/user/UserList";
import UserOne from "pages/user/UserOne";
import OpenDoor from "pages/OpenDoor";
import PasswordResetRequest from "pages/passwordReset/PasswordResetRequest";
import PasswordReset from "pages/passwordReset/PasswordReset";
import NoPage from "pages/NoPage";
import LogList from "pages/LogList";
import ProtectedRoute from "components/ProtectedRoute";
import ServerList from "pages/server/ServerList";
import ServerOne from "pages/server/ServerOne";
import GadgetList from "pages/gadget/GadgetList";
import GadgetOne from "pages/gadget/GadgetOne";
import DemoList from "pages/demo/DemoList";
import DemoOne from "pages/demo/DemoOne";
import Demo from "pages/demo/Demo";
import useCurrentUser from "hooks/useCurrentUser";
import LoginAttemptList from "pages/LoginAttemptList";
import Registration from "pages/Registration";
import ReactGA from "react-ga4";

ReactGA.initialize("G-QNCRLNM9PJ");

const App = () => {
    const { currentUser, logIn } = useCurrentUser();

    library.add(fas);

    return (
        <Router>
            <Routes>
                <Route element={<Layout currentUser={currentUser} />}>
                    <Route
                        path="/"
                        element={<Index currentUser={currentUser} />}
                    />
                    <Route
                        path="login"
                        element={
                            <Login currentUser={currentUser} logIn={logIn} />
                        }
                    />
                    <Route
                        path="login/:token"
                        element={
                            <Login currentUser={currentUser} logIn={logIn} />
                        }
                    />
                    <Route
                        path="confirmation/:id"
                        element={
                            <Login currentUser={currentUser} logIn={logIn} />
                        }
                    />
                    <Route
                        path="signup"
                        element={<Registration currentUser={currentUser} />}
                    />
                    <Route
                        path="password-reset/:id"
                        element={<PasswordReset currentUser={currentUser} />}
                    />
                    <Route
                        path="password-reset"
                        element={
                            <PasswordResetRequest currentUser={currentUser} />
                        }
                    />
                    <Route
                        path="nanoscale2023/:id"
                        element={<OpenDoor currentUser={currentUser} />}
                    />

                    <Route
                        path="profile"
                        element={<Profile currentUser={currentUser} />}
                    />
                    <Route
                        path="modify-password"
                        element={
                            <ProtectedRoute>
                                <ModPassword />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="modify-email"
                        element={
                            <ProtectedRoute>
                                <ModEmail currentUser={currentUser} />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="users"
                        element={
                            <ProtectedRoute>
                                <UserList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="users/:id"
                        element={
                            <ProtectedRoute>
                                <UserOne />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="users/new"
                        element={
                            <ProtectedRoute>
                                <UserOne />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="servers"
                        element={
                            <ProtectedRoute>
                                <ServerList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="servers/:id"
                        element={
                            <ProtectedRoute>
                                <ServerOne />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="servers/new"
                        element={
                            <ProtectedRoute>
                                <ServerOne />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="gadgets"
                        element={
                            <ProtectedRoute>
                                <GadgetList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="gadgets/:id"
                        element={
                            <ProtectedRoute>
                                <GadgetOne />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="gadgets/new"
                        element={
                            <ProtectedRoute>
                                <GadgetOne />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="demos"
                        element={
                            <ProtectedRoute>
                                <DemoList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="demos/:id"
                        element={
                            <ProtectedRoute>
                                <DemoOne />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="demos/new"
                        element={
                            <ProtectedRoute>
                                <DemoOne />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="demo/:id"
                        element={
                            <ProtectedRoute>
                                <Demo />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="logins"
                        element={
                            <ProtectedRoute>
                                <LogList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="banned"
                        element={
                            <ProtectedRoute>
                                <LoginAttemptList />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </Router>
    );
};

export default App;
