import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { User, LoggedUserMod } from "Types";
import { useEffect, useState } from "react";
import { Container, Button, Alert, Form } from "react-bootstrap";
import userService from "services/userService";
import { useNavigate } from "react-router-dom";
import userValidation from "services/userValidation";
import CustomCard from "components/CustomCard";
import { alert } from "components/MessageDialog";

const ModEmail = ({ currentUser }: { currentUser: User | undefined }) => {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<LoggedUserMod>({
        resolver: yupResolver(userValidation.updateEmailSchema),
    });

    useEffect(() => {
        if (currentUser) {
            reset({
                email: currentUser.email,
            });
        }
    }, [currentUser, reset]);

    const onSubmit = (data: LoggedUserMod) => {
        setLoading(true);
        setMessage("");

        userService
            .updateLoggedUser(data)
            .then((response) => {
                //console.log(response);
                setLoading(false);
                alert(
                    "We have sent an email with a confirmation link to your email address. In order to complete the email modification process, please click the confirmation link. If you do not receive a confirmation email, please check your spam folder. Also, please verify that you entered a valid email address in the form."
                );
                navigate("/profile");
            })
            .catch((error) => {
                setLoading(false);
                setMessage(error);
            });
    };

    return (
        <Container>
            <CustomCard header="Modify email">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {message && <Alert variant="info">{message}</Alert>}
                    <Form.Group className="mb-3">
                        <Form.Label>Current password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Current password"
                            {...register("oldPassword")}
                        />
                        <Form.Text className="text-muted">
                            Please enter your current password for security
                            reasons.
                        </Form.Text>
                        {errors.oldPassword && (
                            <Alert>{errors.oldPassword?.message}</Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            {...register("email")}
                        />
                        {errors.email && <Alert>{errors.email?.message}</Alert>}
                    </Form.Group>
                    <Button
                        onClick={() => navigate(-1)}
                        variant="secondary"
                        disabled={loading}
                    >
                        Back
                    </Button>{" "}
                    <Button type="submit" disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Save
                    </Button>
                </Form>
            </CustomCard>
        </Container>
    );
};

export default ModEmail;
