import eventBus from "common/eventBus";
import { gadgetProps } from "config/GadgetConfig";
import { useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import demoService from "services/demoService";
import gadgetService from "services/gadgetService";
import { Demo as DemoType } from "Types";
import Gadget from "../gadget/Gadget";

const Demo = () => {
    const [message, setMessage] = useState("");
    const [data, setData] = useState<DemoType | null>(null);
    const [tokens, setTokens] = useState<any>({});
    const params = useParams();

    useEffect(() => {
        if (params.id) {
            getDemo(params.id);
        }
        // eslint-disable-next-line
    }, [params.id]);

    /*useEffect(() => {
        console.log(tokens);
    }, [tokens]);*/

    useEffect(() => {
        if (data && data.title) {
            eventBus.dispatch("showDemoTitle", data.title);
        }

        return () => {
            eventBus.dispatch("hideDemoTitle");
        };
    }, [data]);

    const getGadgetTokens = (gadgets: string[]) => {
        let t = {};
        gadgets.forEach((gadget: any) => {
            gadgetService.getGadgetToken(gadget._id).then(
                (response) => {
                    if (response.data && response.data.token) {
                        t = {
                            ...t,
                            [gadget._id]: response.data.token,
                        };
                        setTokens(t);
                    }
                },
                (error) => {
                    console.log("Error getting the gadget token!");
                    t = {
                        ...t,
                        [gadget._id]: "INVALID",
                    };
                    setTokens(t);
                }
            );
        });
    };

    const getDemo = (id: string) => {
        return demoService.getDemo(id).then(
            (response) => {
                if (response.data) {
                    response.data.id = response.data._id;
                    getGadgetTokens(response.data.gadgets);
                    setData(response.data);
                }
            },
            (error) => {
                setMessage(error);
            }
        );
    };

    // Draw gadgets by gadget structure
    const drawGadgets = (gadgetKeys: (number | number[])[], i: number): any => {
        return data ? (
            gadgetKeys.map((gadgetKey: number[] | number, index: number) => {
                if (Array.isArray(gadgetKey)) {
                    let xxl = 12;
                    let xl = 12;
                    if (data.template === "default") {
                        xxl = 4;
                        xl = 5;
                        if (index === 1) {
                            xxl = 8;
                            xl = 7;
                        }
                    } else if (data.template === "half") {
                        xxl = 6;
                    }
                    return (
                        <Col key={index + "_" + i + "c"} xxl={xxl} xl={xl}>
                            {drawGadgets(gadgetKey, index + i)}
                        </Col>
                    );
                } else {
                    let json = {};
                    try {
                        json = JSON.parse(data.gadgets[gadgetKey].params ?? "");
                    } catch (err) {}
                    let key: any = data.gadgets[gadgetKey]._id;
                    return (
                        <Gadget
                            key={index + "_" + i + data.gadgets[gadgetKey]._id}
                            id={data.gadgets[gadgetKey]._id}
                            component={data.gadgets[gadgetKey].component}
                            type={
                                gadgetProps[data.gadgets[gadgetKey].component]
                                    .type
                            }
                            server={
                                data.gadgets[gadgetKey].server
                                    ? {
                                          host: data.gadgets[gadgetKey].server
                                              .host,
                                          port:
                                              data.gadgets[gadgetKey].server
                                                  .port ?? 0,
                                      }
                                    : undefined
                            }
                            token={tokens[key] ?? ""}
                            connect={true}
                            title={data.gadgets[gadgetKey].title}
                            desc={data.gadgets[gadgetKey].desc}
                            params={json}
                            open={data.gadgets[gadgetKey].open}
                        />
                    );
                }
            })
        ) : (
            <></>
        );
    };

    return (
        <Container>
            {message && <Alert variant="danger">{message}</Alert>}
            <Row key={params.id}>
                {data &&
                    Object.keys(tokens).length === data.gadgets.length &&
                    drawGadgets(data.gadgetStructure, 1)}
            </Row>
        </Container>
    );
};

export default Demo;
