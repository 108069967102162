import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Gadget } from "Types";
import { MouseEventHandler, useEffect, useState } from "react";
import { Button, Alert, Form } from "react-bootstrap";
import gadgetService from "services/gadgetService";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { alert } from "components/MessageDialog";
import { Server } from "Types";
import serverService from "services/serverService";
import { gadgetProps } from "config/GadgetConfig";
import GadgetAclInputs from "./GadgetAclInputs";
import TagsInput from "components/TagsInput";

const GadgetForm = ({
    data,
    back,
}: {
    data?: Gadget | null;
    back?: MouseEventHandler<HTMLButtonElement>;
}) => {
    const [gadget, setGadget] = useState<Gadget>();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [servers, setServers] = useState<Server[]>();
    const [permissionValueSet, setPermissionValueSet] = useState(["read"]);
    const navigate = useNavigate();

    useEffect(() => getServers(), []);

    useEffect(() => {
        if (data) {
            setGadget(data);
            if (data.permissionValueSet) {
                setPermissionValueSet(data.permissionValueSet);
            }
        }
    }, [data]);

    const schema = Yup.object({
        //serverId: Yup.string().required("Please select a server!"),
        component: Yup.string().required("Please select a variant!"),
        title: Yup.string().required("This field is required!"),
        params: Yup.string().test("isJSON", "Invalid JSON!", (val) => {
            if (val) {
                try {
                    JSON.parse(val);
                } catch (e) {
                    return false;
                }
            }
            return true;
        }),
    }).required();

    const methods = useForm<Gadget>({
        resolver: yupResolver(schema),
    });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = methods;

    useEffect(() => {
        if (gadget && gadget.server) {
            setValue("serverId", gadget.server._id);
        } else {
            setValue("serverId", "");
        }
    }, [servers, setValue, gadget]);

    useEffect(() => {
        if (gadget) {
            reset({
                id: gadget.id,
                component: gadget.component,
                params: gadget.params,
                type: gadget.type,
                title: gadget.title,
                desc: gadget.desc,
                open: gadget.open,
                permissionValueSet: gadget.permissionValueSet,
            });
        } else {
            reset({
                component: "",
                open: true,
                permissionValueSet: ["read"],
            });
        }
    }, [gadget, reset]);

    const getServers = () => {
        serverService.getServers().then(
            (response) => {
                if (response.data) {
                    setServers(response.data);
                }
            },
            (error) => {
                setMessage(error);
            }
        );
    };

    const onSubmit = (data: Gadget) => {
        setLoading(true);
        setMessage("");

        if (!data.id) {
            gadgetService.createGadget(data).then(
                (response) => {
                    setLoading(false);
                    alert("Successfully created the gadget!");
                    navigate("/gadgets");
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        } else {
            gadgetService.updateGadget(data.id, data).then(
                (response) => {
                    setLoading(false);
                    setMessage("Successfully saved.");
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        }
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {message && <Alert variant="info">{message}</Alert>}
                <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="hidden" {...register("id")} />
                    <Form.Control
                        type="text"
                        placeholder="Title"
                        {...register("title")}
                    />
                    {errors.title && <Alert>{errors.title?.message}</Alert>}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        {...register("desc")}
                    />
                    {errors.desc && <Alert>{errors.desc?.message}</Alert>}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Variant</Form.Label>
                    <Form.Select {...register("component")}>
                        <option value="">
                            Select a variant from the list...
                        </option>
                        {Object.keys(gadgetProps).map(function (key) {
                            return (
                                <option value={key}>
                                    {gadgetProps[key].title}
                                </option>
                            );
                        })}
                    </Form.Select>
                    {errors.component && (
                        <Alert>{errors.component?.message}</Alert>
                    )}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Server</Form.Label>
                    <Form.Select
                        {...register("serverId", {
                            onChange: (e) => {
                                setValue("serverId", e.target.value);
                            },
                        })}
                    >
                        <option value="">
                            Select a server from the list...
                        </option>
                        {servers &&
                            servers.map((s, i) => (
                                <option key={`server${i}`} value={s._id}>
                                    {s.name +
                                        " [" +
                                        s.host +
                                        ":" +
                                        s.port +
                                        "]"}
                                </option>
                            ))}
                    </Form.Select>
                    {errors.serverId && (
                        <Alert>{errors.serverId.message}</Alert>
                    )}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Permission value set</Form.Label>
                    <TagsInput
                        initTags={permissionValueSet}
                        selectedTags={(permissionValueSetList) => {
                            setValue(
                                "permissionValueSet",
                                permissionValueSetList
                            );
                        }}
                        placeholder="Press space to add a new permission value to the set"
                        onBlur={(tags) => {
                            setPermissionValueSet(tags);
                        }}
                    />
                </Form.Group>
                <GadgetAclInputs
                    gadgetId={data && data.id ? data.id : ""}
                    permissionValueSet={permissionValueSet}
                />
                <Form.Group className="mb-3">
                    <Form.Label>Parameters (JSON)</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        {...register("params")}
                    />
                    {errors.params && <Alert>{errors.params?.message}</Alert>}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Open status</Form.Label>
                    <Form.Check
                        type="switch"
                        label="Open the panel by default"
                        {...register("open")}
                    />
                    {errors.open && <Alert>{errors.open?.message}</Alert>}
                </Form.Group>
                {back && (
                    <Button
                        onClick={back}
                        variant="secondary"
                        disabled={loading}
                    >
                        Back
                    </Button>
                )}{" "}
                <Button type="submit" disabled={loading}>
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    Save
                </Button>
            </Form>
        </FormProvider>
    );
};

export default GadgetForm;
