import { useState, useEffect } from "react";
import editableContentService from "./editableContentService";
import { EditableContent } from "Types";

const useEditableContentData = ({
    id,
}: {
    id: string;
}): {
    errorMessage: string;
    data: EditableContent | undefined;
    save: (content: string) => void;
} => {
    const [errorMessage, setErrorMessage] = useState("");
    const [data, setData] = useState<EditableContent>();

    useEffect(() => {
        getContent();
        // eslint-disable-next-line
    }, []);

    const getContent = () => {
        editableContentService
            .getEditableContentByGadgetId(id)
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setErrorMessage(err);
            });
    };

    const save = (content: string) => {
        console.log(data);
        if (!data) {
            editableContentService
                .createEditableContent({ gadgetId: id, content: content })
                .then((res) => {
                    setErrorMessage("Successfully saved.");
                })
                .catch((err) => {
                    setErrorMessage(err);
                });
        } else {
            editableContentService
                .updateEditableContent(data._id ?? "", {
                    gadgetId: id,
                    content: content,
                })
                .then((res) => {
                    setErrorMessage("Successfully saved.");
                })
                .catch((err) => {
                    setErrorMessage(err);
                });
            getContent();
        }
    };

    return {
        errorMessage,
        data,
        save,
    };
};

export default useEditableContentData;
