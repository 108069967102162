import { useEffect, useState, useContext } from "react";
import serverService from "services/serverService";
import CustomCard from "components/CustomCard";
import { Button, Container, Alert } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { Server } from "Types";
import Moment from "moment";
import Restricted from "components/Restricted";
import PermissionContext from "common/PermissionContext";
import { Link } from "react-router-dom";
import { confirm } from "components/ConfirmDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ServerList = () => {
    const [message, setMessage] = useState("");
    const [response, setResponse] = useState([]);
    const [loading, setLoading] = useState(false);

    const { isAllowedTo } = useContext(PermissionContext);

    useEffect(() => {
        getServersList();
    }, []);

    const handleDelete = async (id: string) => {
        if (
            await confirm(
                "Are you sure you wish to delete this item? It's cannot be undone."
            )
        ) {
            serverService.deleteServer(id).then(
                (response) => {
                    setMessage("Successfully deleted.");
                    getServersList();
                },
                (error) => {
                    setMessage(error);
                }
            );
        }
    };

    const getServersList = () => {
        setLoading(true);
        serverService.getServers().then(
            (response) => {
                if (response.data) {
                    setResponse(response.data);
                }
                setLoading(false);
            },
            (error) => {
                setMessage(error);
                setLoading(false);
            }
        );
    };

    const columns: TableColumn<Server>[] = [
        {
            name: "Id",
            selector: (row) => row._id,
            omit: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
            format: (row) => <Link to={`/servers/${row._id}`}>{row.name}</Link>,
            sortable: true,
            minWidth: "300px",
            allowOverflow: true,
        },
        {
            name: "Host",
            selector: (row) => row.host,
            sortable: true,
            minWidth: "300px",
        },
        {
            name: "Port",
            selector: (row) => row.port,
            sortable: true,
        },
        ...(isAllowedTo("servers.modify")
            ? [
                  {
                      name: "Created at",
                      selector: (row: Server) =>
                          Moment(row.createdAt).format("DD/MM/YYYY"),
                      sortable: true,
                  },
                  {
                      name: "Updated at",
                      selector: (row: Server) =>
                          Moment(row.updatedAt).format("DD/MM/YYYY"),
                      sortable: true,
                  },
              ]
            : []),
        ...(isAllowedTo("servers.delete") || isAllowedTo("servers.modify")
            ? [
                  {
                      name: "Actions",
                      cell: (row: Server) => (
                          <div className="row-actions">
                              <Restricted to="servers.modify">
                                  <Link to={`/servers/${row._id}?edit=1`}>
                                      <Button title="Edit">
                                          <FontAwesomeIcon icon="pencil" />
                                      </Button>
                                  </Link>
                              </Restricted>
                              <Restricted to="servers.delete">
                                  <Button
                                      variant="danger"
                                      onClick={() => {
                                          handleDelete(row._id);
                                      }}
                                      title="Delete"
                                  >
                                      <FontAwesomeIcon icon="trash-can" />
                                  </Button>
                              </Restricted>
                          </div>
                      ),
                      minWidth: "125px",
                  },
              ]
            : []),
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
            },
        },
    };

    return (
        <Container>
            <CustomCard
                header="Servers"
                infoText="On this page, you can view a list of servers. By clicking on the name, you can view details about that server. To create a new server, simply click on the 'Add server' button."
            >
                {message && <Alert variant="info">{message}</Alert>}
                <Restricted to="servers.modify">
                    <Link to="/servers/new">
                        <Button>Add server</Button>
                    </Link>
                </Restricted>
                <DataTable
                    columns={columns}
                    data={response}
                    pagination
                    customStyles={customStyles}
                    striped
                    progressPending={loading}
                />
            </CustomCard>
        </Container>
    );
};

export default ServerList;
