import api from "services/api";
import { User } from "Types";
import userService from "./userService";
import eventBus from "common/eventBus";

const API_URL = "auth/";

const register = (user: User) => {
    return api.post(API_URL + "signup", user).then((response) => {
        // send email confirmation
        return api.post(
            API_URL + "confirmation",
            {
                email: response.data.email,
                link:
                    window.location.protocol +
                    "//" +
                    window.location.hostname +
                    (window.location.port ? ":" + window.location.port : "") +
                    "/confirmation/",
                userId: response.data.id,
            },
            { timeout: 10000 }
        );
    });
};

const confirmation = (code: string) => {
    return api.get(API_URL + "confirmation/" + code);
};

const login = (username: string, password: string) => {
    let params = {
        username,
        password,
    };
    return api.post(API_URL + "signin", params).then((response) => {
        if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
    });
};

const loginWithToken = (token: string) => {
    return api.post(API_URL + "signin/" + token).then((response) => {
        if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
    });
};

const logout = () => {
    localStorage.removeItem("user");
};

const getCurrentUser = () => {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);

    return null;
};

const updateCurrentUserWithData = (data: Object) => {
    let currentUserData = getCurrentUser();
    let newData = currentUserData;
    if (currentUserData) {
        newData = {
            ...currentUserData,
            ...data,
        };
    }
    localStorage.setItem("user", JSON.stringify(newData));
};

const refreshCurrentUser = () => {
    userService
        .getLoggedUser()
        .then((response) => {
            if (response.data.id) {
                let currentUser = getCurrentUser();
                if (currentUser) {
                    const newUser = {
                        ...currentUser,
                        email: response.data.email,
                        username: response.data.username,
                        permissions: response.data.permissions,
                        role: response.data.role,
                    };

                    localStorage.setItem("user", JSON.stringify(newUser));
                    eventBus.dispatch("refreshCurrentUser");
                }
            }
        })
        .catch((error) => {
            console.log("Error retrieving the current user");
            logout();
        });
};

const getGuestToken = () => {
    return api.get(API_URL + "guest-token");
};

const authService = {
    register,
    confirmation,
    login,
    loginWithToken,
    logout,
    getCurrentUser,
    refreshCurrentUser,
    updateCurrentUserWithData,
    getGuestToken,
};

export default authService;
