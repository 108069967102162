import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Server } from "Types";
import { MouseEventHandler, useEffect, useState } from "react";
import { Button, Alert, Form } from "react-bootstrap";
import serverService from "services/serverService";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { alert } from "components/MessageDialog";

const ServerForm = ({
    data,
    back,
}: {
    data?: Server | null;
    back?: MouseEventHandler<HTMLButtonElement>;
}) => {
    const [server, setServer] = useState<Server>();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const schema = Yup.object({
        token: Yup.string()
            .test(
                "len",
                "The token must be at least 12 characters.",
                (val: any) => val && val.toString().length >= 12
            )
            .matches(
                /^[^\s]*$/,
                "The token cannot contain any whitespace characters."
            )
            .required("This field is required!"),
        host: Yup.string().required("This field is required!"),
        //port: Yup.string().required("This field is required!"),
    }).required();

    const {
        register,
        handleSubmit,
        reset,
        //setValue,
        formState: { errors },
    } = useForm<Server>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (data) {
            setServer(data);
        }
    }, [data]);

    useEffect(() => {
        if (server) {
            reset({
                id: server.id,
                name: server.name,
                type: server.type,
                host: server.host,
                port: server.port,
                token: server.token,
            });
        }
    }, [server, reset]);

    const onSubmit = (data: Server) => {
        setLoading(true);
        setMessage("");

        if (!data.id) {
            serverService.createServer(data).then(
                (response) => {
                    setLoading(false);
                    alert("Successfully created the server!");
                    navigate("/servers");
                    //navigate("/servers/" + response.data._id);
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        } else {
            serverService.updateServer(data.id, data).then(
                (response) => {
                    setLoading(false);
                    setMessage("Successfully saved.");
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {message && <Alert variant="info">{message}</Alert>}
            <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="hidden" {...register("id")} />
                <Form.Control
                    type="text"
                    placeholder="Name"
                    {...register("name")}
                />
                {errors.name && <Alert>{errors.name?.message}</Alert>}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Host</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Host"
                    {...register("host")}
                />
                {errors.host && <Alert>{errors.host?.message}</Alert>}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Port</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Port"
                    {...register("port")}
                />
                {errors.port && <Alert>{errors.port?.message}</Alert>}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Token</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Token"
                    {...register("token")}
                />
                {errors.token && <Alert>{errors.token?.message}</Alert>}
            </Form.Group>
            <Button
                onClick={back ? back : () => navigate(-1)}
                variant="secondary"
                disabled={loading}
            >
                Back
            </Button>{" "}
            <Button type="submit" disabled={loading}>
                {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                )}
                Save
            </Button>
        </Form>
    );
};

export default ServerForm;
