import { MultiSelect, Option } from "react-multi-select-component";
import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import userService from "services/userService";
import {
    AssociativeArrayOfStringArrays,
    GadgetAcl,
    GadgetAcl as GadgetAclType,
    User,
} from "Types";
import gadgetAclService from "services/gadgetAclService";
import { useFormContext } from "react-hook-form";

interface AssociativeOptions {
    [key: string]: Option[];
}

const GadgetAclInputs = ({
    gadgetId = "",
    permissionValueSet = ["read", "write"],
}: {
    gadgetId: string;
    permissionValueSet?: string[];
}) => {
    const [gadgetAcls, setGadgetAcls] = useState<GadgetAclType[]>();
    const [users, setUsers] = useState<[User]>();
    const [selectedUsers, setSelectedUsers] = useState<AssociativeOptions>({});
    const { register, setValue } = useFormContext();

    useEffect(() => {
        let aclInputs = selectedUsers;
        let aclsToDb: GadgetAcl[] = [];
        let permissions: AssociativeArrayOfStringArrays = {};
        for (const property in aclInputs) {
            if (aclInputs[property] && aclInputs[property].length > 0) {
                aclInputs[property].forEach((val: Option) => {
                    if (val.value) {
                        permissions[val.value] = [
                            ...(permissions[val.value] ?? []),
                            property,
                        ];
                        let isUserExistAlready = aclsToDb.find(
                            (item) => item.userId === val.value
                        );
                        //if item with the same userid existed, we replace it (del and push again with the expanded permission array)
                        if (isUserExistAlready) {
                            const index = aclsToDb.indexOf(isUserExistAlready);
                            aclsToDb.splice(index, 1);
                        }
                        aclsToDb.push({
                            gadgetId: gadgetId,
                            userId: val.value,
                            permissions: permissions[val.value],
                        });
                    }
                });
            }
        }
        setValue("gadgetAcl", JSON.stringify(aclsToDb));
    }, [selectedUsers, setValue, gadgetId]);

    useEffect(() => {
        if (gadgetId) {
            getGadgetAcls(gadgetId);
        }
    }, [gadgetId]);

    useEffect(() => {
        userService.getUsers().then(
            (response) => {
                if (response.data) {
                    setUsers(response.data);
                }
            },
            (error) => {
                console.log(error.message);
            }
        );
    }, []);

    useEffect(() => {
        if (gadgetAcls && users) {
            const getSelected = () => {
                let selected: AssociativeOptions = {};
                if (gadgetAcls && gadgetAcls.length > 0 && users) {
                    gadgetAcls.forEach((acl) => {
                        if (acl.permissions.length > 0) {
                            acl.permissions.forEach((permissionVal: string) => {
                                let u = users.find((o) => o._id === acl.userId);
                                selected = {
                                    ...selected,
                                    [permissionVal]: [
                                        {
                                            label: u ? u.username : "",
                                            value: acl.userId,
                                        },
                                        ...(selected[permissionVal] ?? []),
                                    ],
                                };
                            });
                        }
                    });
                }
                return selected;
            };
            //console.log("Selected from db:", getSelected());
            setSelectedUsers(getSelected());
        }
    }, [gadgetAcls, users]);

    const getGadgetAcls = (gadgetId: string) => {
        gadgetAclService.getGadgetAcls({ gadgetId: gadgetId }).then(
            (response) => {
                if (response.data) {
                    setGadgetAcls(response.data);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const drawUserSelector = (
        permissionValue: string,
        users: User[],
        i: number
    ) => {
        return (
            <Form.Group
                className="mb-3"
                key={"setpermission-group-" + permissionValue + i}
            >
                <Form.Label key={"setpermission-label-" + permissionValue + i}>
                    Grant <strong>{permissionValue}</strong> permission to
                </Form.Label>

                <MultiSelect
                    key={"setpermission-select-" + permissionValue + i}
                    options={users.map(({ username, _id }) => ({
                        label: username,
                        value: _id,
                    }))}
                    value={selectedUsers[permissionValue] ?? []}
                    onChange={(val: Option[]) => {
                        setSelectedUsers({
                            ...selectedUsers,
                            [permissionValue]: val,
                        });
                    }}
                    labelledBy={"Select users..."}
                    isCreatable={true}
                    overrideStrings={{
                        allItemsAreSelected: "All users",
                        selectSomeItems: "Select users...",
                    }}
                />
            </Form.Group>
        );
    };

    return (
        <>
            {permissionValueSet &&
                permissionValueSet.map((value: string, i) => {
                    return drawUserSelector(value, users ?? [], i);
                })}

            <input
                key={"permission-input-" + gadgetId}
                type="hidden"
                {...register("gadgetAcl")}
            />
        </>
    );
};

export default GadgetAclInputs;
