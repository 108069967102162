import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { User } from "Types";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import { Button, Alert, Form } from "react-bootstrap";
import userService from "services/userService";
import { useNavigate } from "react-router-dom";
import userValidation from "services/userValidation";
import { alert } from "components/MessageDialog";
import Restricted from "components/Restricted";
import PermissionContext from "common/PermissionContext";
//import UserDemoAclInput from "./UserDemoAclInput";
//import demoAclService from "services/demoAclService";
import UserDemoAclAdmin from "./UserDemoAclAdmin";

const UserForm = ({
    data,
    back,
}: {
    data?: User | null;
    back?: MouseEventHandler<HTMLButtonElement>;
}) => {
    const [user, setUser] = useState<User>();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { isAllowedTo } = useContext(PermissionContext);

    const methods = useForm<User>({
        resolver: yupResolver(
            user ? userValidation.updateSchema : userValidation.createSchema
        ),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = methods;

    useEffect(() => {
        if (data) {
            setUser(data);
        }
    }, [data]);

    useEffect(() => {
        if (user) {
            if (
                user.role === "superadmin" &&
                !isAllowedTo("users.modifysuperadmin")
            ) {
                alert("You don't have permission to modify a superadmin!");
                navigate("/users");
            }
            reset({
                id: user.id,
                username: user.username,
                email: user.email,
                role: user.role,
            });
        }
    }, [user, reset, isAllowedTo, navigate]);

    /*const handleDemoAcls = (data: User, callback: () => void) => {
        if (data.demoAcl) {
            let aclsToDb = JSON.parse(data.demoAcl);

            if (data.id) {
                // Clear all the old demo ACL rules for this demo
                demoAclService.deleteDemoAclByUserId(data.id);
            }

            if (aclsToDb.length > 0 && data.id) {
                let cnt = 0;

                const call = () => {
                    cnt++;
                    if (cnt >= aclsToDb.length) {
                        callback();
                    }
                };

                // Save to DB the new rules
                for (const acl of aclsToDb) {
                    demoAclService
                        .createDemoAcl({ ...acl, userId: data.id })
                        .then(
                            (response) => {
                                call();
                            },
                            (error) => {
                                call();
                            }
                        );
                }
            } else {
                callback();
            }
        } else {
            callback();
        }
    };*/

    const onSubmit = (data: User) => {
        setLoading(true);
        setMessage("");

        if (!data.id) {
            userService.createUser(data).then(
                (response) => {
                    //handleDemoAcls({ ...data, id: response.data._id }, () => {
                    setLoading(false);
                    alert("Successfully created the user!");
                    navigate("/users/" + response.data._id + "?edit=1&new=1");
                    //});
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        } else {
            userService.updateUser(data.id, data).then(
                (response) => {
                    //handleDemoAcls(data, () => {
                    setLoading(false);
                    setMessage("Successfully saved.");
                    //});
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        }
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {message && <Alert variant="info">{message}</Alert>}
                <Form.Group className="mb-3">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="hidden" {...register("id")} />
                    <Form.Control
                        type="text"
                        placeholder="Username"
                        {...register("username")}
                    />
                    {errors.username && (
                        <Alert>{errors.username?.message}</Alert>
                    )}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Email"
                        {...register("email")}
                    />
                    {errors.email && <Alert>{errors.email?.message}</Alert>}
                </Form.Group>
                {!user && (
                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Password"
                            {...register("password")}
                        />
                        {errors.password && (
                            <Alert>{errors.password?.message}</Alert>
                        )}
                    </Form.Group>
                )}
                <Form.Group className="mb-3">
                    <Form.Label>Role</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        {...register("role")}
                    >
                        <option value="guest">Guest</option>
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                        <Restricted to="users.modifysuperadmin">
                            <option value="superadmin">Superadmin</option>
                        </Restricted>
                    </Form.Select>
                    {errors.role && <Alert>{errors.role?.message}</Alert>}
                </Form.Group>
                <UserDemoAclAdmin userId={data && data.id} />
                <Button
                    onClick={back ? back : () => navigate(-1)}
                    variant="secondary"
                    disabled={loading}
                >
                    Back
                </Button>{" "}
                <Button type="submit" disabled={loading}>
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    Save
                </Button>
            </Form>
        </FormProvider>
    );
};

export default UserForm;
