import api from "services/api";
import { EditableContent } from "Types";
import authHeader from "services/authHeader";

const API_URL = "editable-contents/";

const getEditableContent = (id: string) => {
    return api.get(API_URL + id, { headers: authHeader() });
};

const getEditableContentByGadgetId = (gadgetId: string) => {
    return api.get(API_URL + "get-by-gadgetid/" + gadgetId, {
        headers: authHeader(),
    });
};

const createEditableContent = (editableContent: EditableContent) => {
    return api.post(API_URL, editableContent, { headers: authHeader() });
};

const updateEditableContent = (
    id: string,
    editableContent: EditableContent
) => {
    return api.put(API_URL + id, editableContent, { headers: authHeader() });
};

const deleteEditableContent = (id: string) => {
    return api.delete(API_URL + id, { headers: authHeader() });
};

const editableContentService = {
    getEditableContent,
    getEditableContentByGadgetId,
    createEditableContent,
    updateEditableContent,
    deleteEditableContent,
};

export default editableContentService;
