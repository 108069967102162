import { useContext, useEffect, useState } from "react";
import loginAttemptService from "services/loginAttemptService";
import CustomCard from "components/CustomCard";
import { Container, Alert, Button } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { LoginAttempt } from "Types";
import Moment from "moment";
import PermissionContext from "common/PermissionContext";
import Restricted from "components/Restricted";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirm } from "components/ConfirmDialog";

const LoginAttemptList = () => {
    const [message, setMessage] = useState("");
    const [response, setResponse] = useState([]);
    const [loading, setLoading] = useState(false);

    const { isAllowedTo } = useContext(PermissionContext);

    useEffect(() => {
        getLoginAttemptsList();
    }, []);

    const getLoginAttemptsList = () => {
        setLoading(true);
        loginAttemptService.getBannedClients().then(
            (response) => {
                if (response.data) {
                    setResponse(response.data);
                }
                setLoading(false);
            },
            (error) => {
                setMessage(error);
                setLoading(false);
            }
        );
    };

    const handleDelete = async (id: string) => {
        if (await confirm("Are you sure you wish to delete this item?")) {
            loginAttemptService.deleteLoginAttempt(id).then(
                (response) => {
                    setMessage("Successfully deleted.");
                    getLoginAttemptsList();
                },
                (error) => {
                    setMessage(error);
                }
            );
        }
    };

    const columns: TableColumn<LoginAttempt>[] = [
        {
            name: "Ip",
            selector: (row) => row.ip,
            sortable: true,
        },
        {
            name: "Username",
            selector: (row) => row.username,
            sortable: true,
        },
        {
            name: "Activities",
            selector: (row) => row.activityCounter,
            sortable: true,
        },
        {
            name: "First activity",
            selector: (row) =>
                Moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss"),
            sortable: true,
            minWidth: "185px",
        },
        {
            name: "Last activity",
            selector: (row) =>
                Moment(row.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
            sortable: true,
            minWidth: "185px",
        },
        {
            name: "Banned until",
            selector: (row) =>
                row.isBanned
                    ? Moment(row.bannedUntilDate).format("DD/MM/YYYY HH:mm:ss")
                    : "-",
            sortable: true,
            minWidth: "185px",
        },
        ...(isAllowedTo("loginAttempts.delete")
            ? [
                  {
                      name: "Actions",
                      cell: (row: LoginAttempt) => (
                          <div className="row-actions">
                              <Restricted to="loginAttempts.delete">
                                  <Button
                                      variant="danger"
                                      onClick={() => {
                                          handleDelete(row._id);
                                      }}
                                      title="Delete"
                                  >
                                      <FontAwesomeIcon icon="trash-can" />
                                  </Button>
                              </Restricted>
                          </div>
                      ),
                  },
              ]
            : []),
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
            },
        },
        headRow: {
            style: {
                borderBottomColor: "#212529",
            },
        },
    };

    return (
        <Container>
            <CustomCard
                header="Banned clients"
                infoText="On this page, you can view a list of banned clients."
            >
                {message && <Alert variant="info">{message}</Alert>}
                <DataTable
                    columns={columns}
                    data={response}
                    pagination
                    customStyles={customStyles}
                    striped
                    progressPending={loading}
                    paginationRowsPerPageOptions={[5, 10, 50, 100]}
                />
            </CustomCard>
        </Container>
    );
};

export default LoginAttemptList;
