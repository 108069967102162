import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const PasswordInput = ({ params }: { params?: Object }) => {
    const [inputType, setInputType] = useState("password");
    const [icon, setIcon] = useState(faEyeSlash);

    const togglePassword = () => {
        setInputType(inputType === "password" ? "text" : "password");
        setIcon(inputType === "password" ? faEye : faEyeSlash);
    };

    return (
        <div className="password-container">
            <Form.Control type={inputType} placeholder="Password" {...params} />
            <FontAwesomeIcon
                className="password-eye"
                icon={icon}
                onClick={() => togglePassword()}
            />
        </div>
    );
};

export default PasswordInput;
