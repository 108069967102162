import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Placement } from "react-bootstrap/esm/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-bootstrap";

function InfoTooltip({
    header,
    text,
    placement = "left",
}: {
    header?: string;
    text: string | undefined;
    placement?: Placement | undefined;
}) {
    return (
        <>
            <OverlayTrigger
                key={placement}
                placement={placement}
                overlay={
                    <Tooltip id="tooltip">
                        {header && <h6>{header}</h6>}
                        {text}
                    </Tooltip>
                }
            >
                <FontAwesomeIcon className="info-button" icon="circle-info" />
            </OverlayTrigger>
        </>
    );
}

export default InfoTooltip;
