import api from "services/api";
import { GadgetAcl } from "Types";
import authHeader from "services/authHeader";

const API_URL = "gadget-acls/";

const getGadgetAcls = (params = {}) => {
    return api.get(API_URL, { headers: authHeader(), params: params });
};

const getGadgetAcl = (id: string) => {
    return api.get(API_URL + id, { headers: authHeader() });
};

const getGadgetAclByGadgetIdAndUserId = (gadgetId: string, userId: string) => {
    return api.get(API_URL + gadgetId + "/" + userId, {
        headers: authHeader(),
    });
};

const createGadgetAcl = (gadgetAcl: GadgetAcl) => {
    return api.post(API_URL, gadgetAcl, { headers: authHeader() });
};

const updateGadgetAcl = (id: string, gadgetAcl: GadgetAcl) => {
    return api.put(API_URL + id, gadgetAcl, { headers: authHeader() });
};

const deleteGadgetAcl = (id: string) => {
    return api.delete(API_URL + id, { headers: authHeader() });
};

const deleteGadgetAclByGadgetId = (gadgetId: string) => {
    return api.delete(API_URL + "del-by-gadgetid/" + gadgetId, {
        headers: authHeader(),
    });
};

const removePermissionsFromUserByDemoId = (userId: string, demoId: string) => {
    return api.delete(API_URL + userId + "/" + demoId, {
        headers: authHeader(),
    });
};

const grantPermissionsToUserByDemoId = (userId: string, demoId: string) => {
    return api.get(API_URL + "grant/" + userId + "/" + demoId, {
        headers: authHeader(),
    });
};

const gadgetAclService = {
    getGadgetAcls,
    getGadgetAcl,
    getGadgetAclByGadgetIdAndUserId,
    createGadgetAcl,
    updateGadgetAcl,
    deleteGadgetAcl,
    deleteGadgetAclByGadgetId,
    removePermissionsFromUserByDemoId,
    grantPermissionsToUserByDemoId,
};

export default gadgetAclService;
