import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Placement } from "react-bootstrap/esm/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function InfoPopover({
    header,
    text,
    placement = "left",
}: {
    header: string;
    text: string | undefined;
    placement?: Placement | undefined;
}) {
    return (
        <>
            <OverlayTrigger
                key={placement}
                placement={placement}
                overlay={
                    <Popover>
                        <Popover.Body>
                            <h6>{header}</h6>
                            {text}
                        </Popover.Body>
                    </Popover>
                }
            >
                <FontAwesomeIcon className="info-button" icon="circle-info" />
            </OverlayTrigger>
        </>
    );
}

export default InfoPopover;
