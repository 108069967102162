import * as yup from "yup";

const usernameRule = yup
    .string()
    .test(
        "len",
        "The username must be between 3 and 20 characters.",
        (val: any) =>
            val && val.toString().length >= 3 && val.toString().length <= 20
    )
    .matches(
        /^[^\s]*$/,
        "The username cannot contain any whitespace characters."
    )
    .required("This field is required!");

const emailRule = yup
    .string()
    .email("This is not a valid email.")
    .required("This field is required!");

const requiredRule = yup.string().required("This field is required!");

const passwordRule = yup
    .string()
    .test(
        "len",
        "The password must be between 6 and 40 characters.",
        (val: any) =>
            val && val.toString().length >= 6 && val.toString().length <= 40
    )
    .required("This field is required!");

const passwordConfirmationRule = yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match.");

const oldPasswordRule = yup.string().required("This field is required!");

const updateSchema = yup.object({
    username: usernameRule,
    email: emailRule,
    role: requiredRule,
});

const createSchema = yup.object({
    username: usernameRule,
    email: emailRule,
    password: passwordRule,
    role: requiredRule,
});

const registrationSchema = yup.object({
    username: usernameRule,
    email: emailRule,
    password: passwordRule,
    passwordConfirmation: passwordConfirmationRule,
});

const loginSchema = yup.object({
    username: requiredRule,
    password: requiredRule,
});

const updatePasswordSchema = yup.object({
    oldPassword: oldPasswordRule,
    password: passwordRule,
    passwordConfirmation: passwordConfirmationRule,
});

const updateEmailSchema = yup.object({
    oldPassword: oldPasswordRule,
    email: emailRule,
});

const userValidation = {
    updateSchema,
    updatePasswordSchema,
    updateEmailSchema,
    createSchema,
    registrationSchema,
    loginSchema,
    passwordRule,
    passwordConfirmationRule,
};

export default userValidation;
