import GadgetCard from "pages/gadget/GadgetCard";
import GadgetFactory from "pages/gadget/GadgetFactory";
import {
    useMidlayerWebsocketImage,
    useMidlayerWebsocketPositionData,
    useMidlayerWebsocketPositionControl,
} from "@anemos/hooks";
import { useState } from "react";
import GadgetOne from "./GadgetOne";
import Restricted from "components/Restricted";
import useEditableContentData from "gadgets/editableContent/useEditableContentData";

const Gadget = ({
    component = "",
    params = {},
    server = { host: "", port: 0 },
    token = "",
    connect = false,
    type = "",
    title = "",
    desc = "",
    open = true,
    id = "",
}) => {
    const [isOpenConfigPanel, setIsOpenConfigPanel] = useState(false);

    const wsPosition = useMidlayerWebsocketPositionData({
        host: server.host,
        port: server.port === 0 ? undefined : server.port,
        token: token,
        shouldConnect: type === "position" && connect,
    });

    const wsImage = useMidlayerWebsocketImage({
        host: server.host,
        port: server.port === 0 ? undefined : server.port,
        token: token,
        shouldConnect: type === "image" && connect,
    });

    const wsControl = useMidlayerWebsocketPositionControl({
        host: server.host,
        port: server.port === 0 ? undefined : server.port,
        token: token,
        shouldConnect: type === "control" && connect,
    });

    const editableContentManager = useEditableContentData({ id: id });

    /*useEffect(() => {
        console.log({
            host: server.host,
            port: server.port === 0 ? undefined : server.port,
            token: token,
            shouldConnect: type === "position" && connect,
        });
    }, []);*/

    const toggleConfigPanel = () => {
        if (isOpenConfigPanel) {
            setIsOpenConfigPanel(false);
        } else {
            setIsOpenConfigPanel(true);
        }
    };

    return (
        <>
            <GadgetCard
                header={title}
                infoText={desc}
                open={open}
                openConfigPanel={toggleConfigPanel}
            >
                <GadgetFactory
                    component={component}
                    source={
                        type === "position"
                            ? wsPosition
                            : type === "image"
                            ? wsImage
                            : type === "control"
                            ? wsControl
                            : editableContentManager
                    }
                    params={params}
                />
            </GadgetCard>
            <Restricted to="gadget.modify">
                <GadgetOne
                    gadgetId={id}
                    inModal={true}
                    defaultEditMode={true}
                    isOpen={isOpenConfigPanel}
                    toggle={toggleConfigPanel}
                />
            </Restricted>
        </>
    );
};

export default Gadget;
