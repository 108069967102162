import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

const actualYear = new Date().getFullYear();

const Footer = () => {
    return (
        <Navbar fixed="bottom" bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Text>
                    ©{actualYear}{" "}
                    <a
                        href="https://anemos.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                    >
                        Anemos{" "}
                    </a>
                    - all rights reserved | v1.0.0
                </Navbar.Text>
            </Container>
        </Navbar>
    );
};

export default Footer;
