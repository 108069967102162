import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Demo } from "Types";
import { MouseEventHandler, useEffect, useState } from "react";
import { Button, Alert, Form } from "react-bootstrap";
import demoService from "services/demoService";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { alert } from "components/MessageDialog";
import { Gadget } from "Types";
import gadgetService from "services/gadgetService";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import DragColumn from "components/DragColumn";
import { MultiSelect, Option } from "react-multi-select-component";

const DemoForm = ({
    data,
    back,
}: {
    data?: Demo | null;
    back?: MouseEventHandler<HTMLButtonElement>;
}) => {
    const [demo, setDemo] = useState<Demo>();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [gadgets, setGadgets] = useState<Gadget[]>([]);
    const navigate = useNavigate();
    let gadgetsList: string[] = [];
    let leftList: string[] = [];
    let rightList: string[] = [];
    const initialColumns = {
        Gadgets: {
            id: "Gadgets",
            list: gadgetsList,
        },
        Left: {
            id: "Left",
            list: leftList,
        },
        Right: {
            id: "Right",
            list: rightList,
        },
    };
    const [columns, setColumns] = useState(initialColumns);
    const [selectedGadgets, setSelectedGadgets] = useState<Option[]>([]);

    useEffect(() => getGadgets(), []);

    useEffect(() => {
        if (selectedGadgets) {
            if (demo && demo.gadgetStructure) {
                demo?.gadgetStructure.forEach((gArr: number[], key: number) => {
                    if (key === 0) {
                        //left
                        gArr.forEach((g) => {
                            if (selectedGadgets[g]) {
                                leftList.push(selectedGadgets[g].label);
                            }
                        });
                    } else if (key === 1) {
                        //right
                        gArr.forEach((g) => {
                            if (selectedGadgets[g]) {
                                rightList.push(selectedGadgets[g].label);
                            }
                        });
                    }
                });
            }

            setColumns({
                Gadgets: {
                    ...initialColumns.Gadgets,
                    list: selectedGadgetsToGadgetTitles().filter(
                        (title) =>
                            !leftList.includes(title) &&
                            !rightList.includes(title)
                    ),
                },
                Left: {
                    ...initialColumns.Left,
                    list: leftList,
                },
                Right: {
                    ...initialColumns.Right,
                    list: rightList,
                },
            });
        }
        // eslint-disable-next-line
    }, [selectedGadgets]);

    useEffect(() => {
        if (demo && demo.gadgets) {
            setSelectedGadgets(
                demo.gadgets.map((gadget) => {
                    return {
                        value: gadget._id,
                        label: gadget.title,
                    };
                })
            );
        }
    }, [demo, gadgets]);

    useEffect(() => {
        if (data) {
            setDemo(data);
        }
    }, [data]);

    const schema = Yup.object({
        title: Yup.string().required("This field is required!"),
        template: Yup.string().required("Please select a template!"),
    }).required();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Demo>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (demo) {
            reset({
                id: demo.id,
                title: demo.title,
                template: demo.template,
                gadgetStructure: demo.gadgetStructure,
                gadgets: demo.gadgets,
            });
        }
    }, [demo, reset]);

    const selectedGadgetsToGadgetTitles = () => {
        return selectedGadgets.map(({ label }) => label);
    };

    const selectedGadgetsToGadgetIds = () => {
        return selectedGadgets.map(({ value }) => value);
    };

    const onDragEnd = ({ source, destination }: DropResult) => {
        // Make sure we have a valid destination
        if (destination === undefined || destination === null) return null;

        //console.log(source, destination);
        // Make sure we're actually moving the item
        if (
            source.droppableId === destination.droppableId &&
            destination.index === source.index
        )
            return null;

        // Set start and end variables
        const start =
            columns[source.droppableId as keyof typeof initialColumns];
        const end =
            columns[destination.droppableId as keyof typeof initialColumns];

        // If start is the same as end, we're in the same column
        if (start === end) {
            // Move the item within the list
            // Start by making a new list without the dragged item
            const newList = start.list.filter(
                (_: any, idx: number) => idx !== source.index
            );

            // Then insert the item at the right location
            newList.splice(destination.index, 0, start.list[source.index]);

            // Then create a new copy of the column object
            const newCol = {
                id: start.id,
                list: newList,
            };

            // Update the state
            setColumns((state) => ({ ...state, [newCol.id]: newCol }));
            return null;
        } else {
            // If start is different from end, we need to update multiple columns
            // Filter the start list like before
            const newStartList = start.list.filter(
                (_: any, idx: number) => idx !== source.index
            );

            // Create a new start column
            const newStartCol = {
                id: start.id,
                list: newStartList,
            };

            // Make a new end list array
            const newEndList = end.list;

            // Insert the item into the end list
            newEndList.splice(destination.index, 0, start.list[source.index]);

            // Create a new end column
            const newEndCol = {
                id: end.id,
                list: newEndList,
            };

            // Update the state
            setColumns((state) => ({
                ...state,
                [newStartCol.id]: newStartCol,
                [newEndCol.id]: newEndCol,
            }));
            return null;
        }
    };

    const getGadgets = () => {
        gadgetService.getGadgets().then(
            (response) => {
                if (response.data) {
                    setGadgets(response.data);
                }
            },
            (error) => {
                setMessage(error);
            }
        );
    };

    const getObjectKey = (obj: any, value: any) => {
        return Object.keys(obj).find((key: any) => obj[key] === value);
    };

    const onSubmit = (data: Demo) => {
        setLoading(true);
        setMessage("");

        data.gadgetIds = selectedGadgetsToGadgetIds();
        data.gadgetStructure = [[], []];

        const gadgetIdsByTitles = selectedGadgetsToGadgetTitles();

        gadgetIdsByTitles.forEach((value, index) => {
            const filteredLeft: any = getObjectKey(columns.Left.list, value);
            if (filteredLeft) {
                data.gadgetStructure[0][filteredLeft] = index;
            }
            const filteredRight: any = getObjectKey(columns.Right.list, value);
            if (filteredRight) {
                data.gadgetStructure[1][filteredRight] = index;
            }
        });

        //console.log(data);

        if (!data.id) {
            demoService.createDemo(data).then(
                (response) => {
                    setLoading(false);
                    alert("Successfully created the demo!");
                    navigate("/demos");
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        } else {
            demoService.updateDemo(data.id, data).then(
                (response) => {
                    setLoading(false);
                    setMessage("Successfully saved.");
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {message && <Alert variant="info">{message}</Alert>}
            <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control type="hidden" {...register("id")} />
                <Form.Control
                    type="text"
                    placeholder="Title"
                    {...register("title")}
                />
                {errors.title && <Alert>{errors.title?.message}</Alert>}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Template</Form.Label>
                <Form.Select {...register("template")}>
                    <option value="">Select a template from the list...</option>
                    <option value="default">Default</option>
                </Form.Select>
                {errors.template && <Alert>{errors.template?.message}</Alert>}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Gadgets</Form.Label>
                <MultiSelect
                    options={gadgets.map(({ title, _id }) => ({
                        label: title,
                        value: _id,
                    }))}
                    value={selectedGadgets}
                    onChange={setSelectedGadgets}
                    labelledBy={"Select gadgets"}
                    isCreatable={true}
                />
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="drag-and-drop-column">
                        {Object.values(columns).map((col) => (
                            <DragColumn col={col} key={col.id} />
                        ))}
                    </div>
                </DragDropContext>
            </Form.Group>
            <Button
                onClick={back ? back : () => navigate(-1)}
                variant="secondary"
                disabled={loading}
            >
                Back
            </Button>{" "}
            <Button type="submit" disabled={loading}>
                {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                )}
                Save
            </Button>
        </Form>
    );
};

export default DemoForm;
