import { Button, Col, Container, Row } from "react-bootstrap";
import CustomCard from "components/CustomCard";
import { Demo, User } from "Types";
import Restricted from "components/Restricted";
import demoService from "services/demoService";
import { useContext, useEffect, useState } from "react";
import PermissionContext from "common/PermissionContext";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const Index = ({ currentUser }: { currentUser: User | undefined }) => {
    const [demo, setDemo] = useState<Demo>();
    const [newUser, setNewUser] = useState(false);
    const { isAllowedTo } = useContext(PermissionContext);

    const getDemos = () => {
        demoService.getDemos().then(
            (response) => {
                if (response.data && response.data.length > 0) {
                    setDemo(response.data[0]);
                    //navigate("/demo/" + demo._id);
                } else {
                    setNewUser(true);
                }
            },
            (error) => {}
        );
    };

    useEffect(() => {
        if (
            currentUser &&
            (isAllowedTo("demos.view") || isAllowedTo("demos.view.own"))
        ) {
            getDemos();
        }
        // eslint-disable-next-line
    }, [currentUser, isAllowedTo]);

    return (
        <Container>
            <Restricted to="demos.modify">
                <CustomCard>
                    <Row className="welcome-box center">
                        {currentUser &&
                            (currentUser.role === "admin" ||
                                currentUser.role === "superadmin") && (
                                <>
                                    <h1>WELCOME ON BOARD</h1>
                                    <p>
                                        Dear <b>{currentUser.username}</b>, have
                                        a nice and productive day!
                                    </p>
                                </>
                            )}

                        {currentUser && currentUser.role === "user" && (
                            <Row className="welcome-box center">
                                <p>
                                    This webpage provides an opportunity to
                                    follow the status of the different hardware
                                    components, if one have permission to
                                    certain elements.
                                </p>
                                {newUser && (
                                    <p>
                                        Currently you don't have permission to
                                        any of our demos. Please{" "}
                                        <a
                                            href="https://anemos.co.uk/contact/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            contact us
                                        </a>{" "}
                                        to get a live presentation!
                                    </p>
                                )}
                            </Row>
                        )}
                    </Row>
                </CustomCard>
            </Restricted>

            <CustomCard>
                <Row className="welcome-box">
                    <h1>Pico Demo</h1>
                    <p>
                        This real time web demo shows full 6DoF MAPS in action
                        with simple interactive nanopositioning or automatic
                        shape scanning at picometre resolutions.
                    </p>
                    <p>
                        Measured system noise is at approximately 100 pm and
                        sub-nanoradian levels using a basic home-grown
                        nanopositioning stage. MAPS intrinsic noise would be
                        expected to be a fraction of system noise. While our
                        demo lab environment is not quite NPL standard,
                        Pico-Demo performance approaches the excellent results
                        of our joint experiments.
                    </p>
                    <p>
                        Playing nano-scale Etcha-a-Sketch can be fun, and it’s
                        easy to forget things are moving in sub-atomic
                        increments!
                    </p>
                    <div className="welcome-page-button-list">
                        <span>
                            {currentUser && demo && demo._id && (
                                <Link to={"/demo/" + demo._id}>
                                    <Button>DEMO</Button>
                                </Link>
                            )}
                        </span>
                        <span>
                            <a
                                href="https://anemos.co.uk/contact/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button>
                                    {currentUser ? "CONTACT" : "BOOK A DEMO"}
                                </Button>
                            </a>
                        </span>
                        <span>
                            <a
                                href="https://anemos.co.uk"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button>MAIN SITE</Button>
                            </a>
                        </span>
                    </div>
                    <img
                        src="/etch-asketch-redux.png"
                        alt="Etch Asketch redux"
                        className="redux-pic"
                    />
                </Row>
            </CustomCard>
            <CustomCard>
                <div className="welcome-box">
                    <img
                        src="/anemos-logo-maps-sirocco.svg"
                        alt="Etch Asketch redux"
                        className="welcome-box-sirocco"
                    />
                    <h2>MAPS setup</h2>

                    <p>
                        The customised MAPS configuration here is from Anemos’
                        Sirocco nanotech product line. Sample rate is set to 2
                        Hz and the quartz glass, 5 µm pitch, reference scale is
                        cut to a 1 cm tab and mounted on a Zerodur cradle with
                        through-scale LED illumination delivered by light-pipe.
                    </p>
                    <h2>Nanopositioning stage</h2>
                    <p>
                        The stage was thrown together using Zerodur offcuts from
                        another project, low cost ($3), piezo disks, and
                        superglue (see picture). While its performance is
                        nonetheless remarkable, it has no intended utility
                        outside this experiment – MAPS is the focus here – and
                        is currently being rebuilt to further improve
                        performance.
                    </p>
                    <p>
                        Movement is generated by piezo disc actuators driven
                        open loop by “32-bit” D/A converters at a few volts of
                        swing achieving just a few nanometres of maximum travel.
                        This generates extremely high-resolution motion without
                        electrical noise or servo loop stability (hunting) being
                        primary concerns. However, some hysteresis, and other
                        effects remain. Zerodur angle blocks provide xyz axis
                        linkages built over a base slab which largely mitigates
                        thermal effects. However, piezos and MAPS components in
                        the kinematic path are not completely immune from
                        environmental fluctuations.
                    </p>
                    <h2>Lab environment</h2>
                    <p>
                        The setup is placed inside a foam insulated cabinet on
                        an isolated optical bench. The room is a closed space,
                        not actively temperature controlled, though thermally
                        isolated - cave-like conditions provide gentle
                        temperature swings. Also, the remote location provides
                        an unusually low-vibration environment (except for
                        occasional low-flying helicopters).
                    </p>
                    <Row>
                        <Col lg="2"></Col>
                        <Col lg="8" sm="12">
                            <img
                                src="/welcome-page-second.jpg"
                                alt="Lab environment"
                                className="welcome-box-second-pic"
                            />
                        </Col>
                        <Col lg="2"></Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <ReactPlayer
                                className="react-player"
                                url="/pico-sketch.mp4"
                                controls={true}
                            />
                        </Col>
                    </Row>
                </div>
            </CustomCard>
        </Container>
    );
};

export default Index;
