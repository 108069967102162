import { useEffect, useState } from "react";
import logService from "services/logService";
import CustomCard from "components/CustomCard";
import { Container, Alert } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { Log } from "Types";
import Moment from "moment";
import { Link } from "react-router-dom";

const LogList = () => {
    const [message, setMessage] = useState("");
    const [response, setResponse] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getLogsList();
    }, []);

    const getLogsList = () => {
        setLoading(true);
        logService.getLogs({ type: "login" }).then(
            (response) => {
                if (response.data) {
                    setResponse(response.data);
                }
                setLoading(false);
            },
            (error) => {
                setMessage(error);
                setLoading(false);
            }
        );
    };

    const columns: TableColumn<Log>[] = [
        {
            name: "Date",
            selector: (row) =>
                Moment(row.createdAt).format("DD/MM/YYYY hh:mm:ss"),
            sortable: true,
        },
        {
            name: "Username",
            selector: (row) => row.username,
            format: (row) => (
                <Link to={`/users/${row.userId}`}>{row.username}</Link>
            ),
            sortable: true,
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
            },
        },
        headRow: {
            style: {
                borderBottomColor: "#212529",
            },
        },
    };

    return (
        <Container>
            <CustomCard
                header="Logins"
                infoText="On this page, you can view a list of user logins."
            >
                {message && <Alert variant="info">{message}</Alert>}
                <DataTable
                    columns={columns}
                    data={response}
                    pagination
                    customStyles={customStyles}
                    striped
                    progressPending={loading}
                    dense
                    paginationRowsPerPageOptions={[5, 10, 50, 100]}
                />
            </CustomCard>
        </Container>
    );
};

export default LogList;
