import api from "services/api";
import { Gadget } from "Types";
import authHeader from "services/authHeader";

const API_URL = "gadgets/";

const getGadgets = () => {
    return api.get(API_URL, { headers: authHeader() });
};

const getGadget = (id: string) => {
    return api.get(API_URL + id, { headers: authHeader() });
};

const createGadget = (gadget: Gadget) => {
    return api.post(API_URL, gadget, { headers: authHeader() });
};

const updateGadget = (id: string, gadget: Gadget) => {
    return api.put(API_URL + id, gadget, { headers: authHeader() });
};

const deleteGadget = (id: string) => {
    return api.delete(API_URL + id, { headers: authHeader() });
};

const getGadgetToken = (id: string) => {
    return api.get(API_URL + "get-token/" + id, { headers: authHeader() });
};

const gadgetService = {
    getGadgets,
    getGadget,
    createGadget,
    updateGadget,
    deleteGadget,
    getGadgetToken,
};

export default gadgetService;
