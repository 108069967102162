import api from "services/api";
import { Demo } from "Types";
import authHeader from "services/authHeader";

const API_URL = "demos/";

const getDemos = () => {
    return api.get(API_URL, { headers: authHeader() });
};

const getDemo = (id: string) => {
    return api.get(API_URL + id, { headers: authHeader() });
};

const createDemo = (demo: Demo) => {
    return api.post(API_URL, demo, { headers: authHeader() });
};

const updateDemo = (id: string, demo: Demo) => {
    return api.put(API_URL + id, demo, { headers: authHeader() });
};

const deleteDemo = (id: string) => {
    return api.delete(API_URL + id, { headers: authHeader() });
};

const demoService = {
    getDemos,
    getDemo,
    createDemo,
    updateDemo,
    deleteDemo,
};

export default demoService;
