import { Outlet } from "react-router-dom";
import NavbarHeader from "components/NavbarHeader";
import Footer from "components/Footer";
import { User } from "Types";
import PermissionProvider from "components/PermissionProvider";
import { MessageDialog } from "components/MessageDialog";

const Layout = ({ currentUser }: { currentUser: User | undefined }) => {
    const permissions = currentUser ? currentUser.permissions : [];

    return (
        <div className="App">
            <PermissionProvider permissions={permissions}>
                <NavbarHeader currentUser={currentUser} />
                <main>
                    <Outlet />
                </main>
                <footer>
                    <Footer />
                </footer>
            </PermissionProvider>
            <MessageDialog />
        </div>
    );
};

export default Layout;
