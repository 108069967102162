import {
    PositionGraph,
    PositionTable,
    StageControl,
    StageControlSimple,
} from "@anemos/position-graph";
import { BareJpegImageViewer } from "@anemos/image-viewer";
import {
    useMidlayerWebsocketImage,
    useMidlayerWebsocketPositionControl,
    useMidlayerWebsocketPositionData,
} from "@anemos/hooks";
import "@anemos/position-graph/dist/style.css";
import AlertGadget from "./AlertGadget";
import EditableContentGadget from "../../gadgets/editableContent/EditableContentGadget";
import useEditableContentData from "gadgets/editableContent/useEditableContentData";

type WsPositionType = ReturnType<typeof useMidlayerWebsocketPositionData>;
type WsImageType = ReturnType<typeof useMidlayerWebsocketImage>;
type WsControlType = ReturnType<typeof useMidlayerWebsocketPositionControl>;
type EditableContentType = ReturnType<typeof useEditableContentData>;

const GadgetFactory = ({
    component = "undefined",
    source,
    params = {},
}: {
    component: string;
    source: WsImageType | WsPositionType | WsControlType | EditableContentType;
    params?: Object;
}) => {
    let GadgetComponent = null;

    switch (component) {
        case "PositionGraph":
            GadgetComponent = PositionGraph;
            params = {
                ...params,
                data: (source as WsPositionType).data,
                errorMessage: (source as WsPositionType).errorMessage,
            };
            break;
        case "PositionTable":
            GadgetComponent = PositionTable;
            params = {
                ...params,
                data: (source as WsPositionType).data,
                errorMessage: (source as WsPositionType).errorMessage,
            };
            break;
        case "ImageViewer":
            GadgetComponent = BareJpegImageViewer;
            params = {
                ...params,
                image: (source as WsImageType).image,
                errorMessage: (source as WsImageType).errorMessage,
                getNext: (source as WsImageType).getImage,
            };
            break;
        case "StageControl":
            GadgetComponent = StageControl;
            params = {
                ...params,
                errorMessage: (source as WsControlType).errorMessage,
                data: (source as WsControlType).data,
                send: (source as WsControlType).send,
            };
            break;
        case "StageControlSimple":
            GadgetComponent = StageControlSimple;
            params = {
                ...params,
                errorMessage: (source as WsControlType).errorMessage,
                data: (source as WsControlType).data,
                send: (source as WsControlType).send,
            };
            break;
        case "Alert":
            GadgetComponent = AlertGadget;
            params = {
                ...params,
            };
            break;
        case "EditableContent":
            GadgetComponent = EditableContentGadget;
            params = {
                ...params,
                errorMessage: (source as EditableContentType).errorMessage,
                data: (source as EditableContentType).data,
                save: (source as EditableContentType).save,
            };
            break;
        default:
            break;
    }

    if (typeof GadgetComponent === "function") {
        //console.log("Gadget Factory Params", params);
        return <GadgetComponent {...params} />;
    }

    console.log("Gadget Factory Error: Unknown component");
    return null; // Render nothing for unknown component names or when component doesn't exist
};

export default GadgetFactory;
