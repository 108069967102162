import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoggedUserMod } from "Types";
import { useState } from "react";
import { Container, Button, Alert, Form } from "react-bootstrap";
import userService from "services/userService";
import { useNavigate } from "react-router-dom";
import userValidation from "services/userValidation";
import CustomCard from "components/CustomCard";

const ModPassword = () => {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoggedUserMod>({
        resolver: yupResolver(userValidation.updatePasswordSchema),
    });

    const onSubmit = (data: LoggedUserMod) => {
        setLoading(true);
        setMessage("");

        userService.updateLoggedUser(data).then(
            (response) => {
                setLoading(false);
                setMessage("Successfully saved.");
            },
            (error) => {
                setLoading(false);
                setMessage(error);
            }
        );
    };

    return (
        <Container>
            <CustomCard header="Modify password">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {message && <Alert variant="info">{message}</Alert>}
                    <Form.Group className="mb-3">
                        <Form.Label>Current password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Current password"
                            {...register("oldPassword")}
                        />
                        {errors.oldPassword && (
                            <Alert>{errors.oldPassword?.message}</Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>New password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            {...register("password")}
                        />
                        {errors.password && (
                            <Alert>{errors.password?.message}</Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Confirm new password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm new password"
                            {...register("passwordConfirmation")}
                        />
                        {errors.passwordConfirmation && (
                            <Alert>
                                {errors.passwordConfirmation?.message}
                            </Alert>
                        )}
                    </Form.Group>
                    <Button
                        onClick={() => navigate(-1)}
                        variant="secondary"
                        disabled={loading}
                    >
                        Back
                    </Button>{" "}
                    <Button type="submit" disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Save
                    </Button>
                </Form>
            </CustomCard>
        </Container>
    );
};

export default ModPassword;
