import api from "services/api";
import { DemoAcl } from "Types";
import authHeader from "services/authHeader";

const API_URL = "demo-acls/";

const getDemoAcls = (params = {}) => {
    return api.get(API_URL, { headers: authHeader(), params: params });
};

const getDemoAcl = (id: string) => {
    return api.get(API_URL + id, { headers: authHeader() });
};

const getDemoAclByDemoIdAndUserId = (demoId: string, userId: string) => {
    return api.get(API_URL + demoId + "/" + userId, {
        headers: authHeader(),
    });
};

const createDemoAcl = (demoAcl: DemoAcl) => {
    return api.post(API_URL, demoAcl, { headers: authHeader() });
};

const updateDemoAcl = (id: string, demoAcl: DemoAcl) => {
    return api.put(API_URL + id, demoAcl, { headers: authHeader() });
};

const deleteDemoAcl = (id: string) => {
    return api.delete(API_URL + id, { headers: authHeader() });
};

const deleteDemoAclByUserId = (userId: string) => {
    return api.delete(API_URL + "del-by-userid/" + userId, {
        headers: authHeader(),
    });
};

const demoAclService = {
    getDemoAcls,
    getDemoAcl,
    getDemoAclByDemoIdAndUserId,
    createDemoAcl,
    updateDemoAcl,
    deleteDemoAcl,
    deleteDemoAclByUserId,
};

export default demoAclService;
