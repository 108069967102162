import { useEffect, useState, useContext } from "react";
import gadgetService from "services/gadgetService";
import CustomCard from "components/CustomCard";
import { Button, Container, Alert } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { Gadget } from "Types";
import Moment from "moment";
import Restricted from "components/Restricted";
import PermissionContext from "common/PermissionContext";
import { Link } from "react-router-dom";
import { confirm } from "components/ConfirmDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GadgetList = () => {
    const [message, setMessage] = useState("");
    const [response, setResponse] = useState([]);
    const [loading, setLoading] = useState(false);

    const { isAllowedTo } = useContext(PermissionContext);

    useEffect(() => {
        getGadgetsList();
    }, []);

    const handleDelete = async (id: string) => {
        if (
            await confirm(
                "Are you sure you wish to delete this item? It's cannot be undone."
            )
        ) {
            gadgetService.deleteGadget(id).then(
                (response) => {
                    setMessage("Successfully deleted.");
                    getGadgetsList();
                },
                (error) => {
                    setMessage(error);
                }
            );
        }
    };

    const getGadgetsList = () => {
        setLoading(true);
        gadgetService.getGadgets().then(
            (response) => {
                if (response.data) {
                    setResponse(response.data);
                }
                setLoading(false);
            },
            (error) => {
                setMessage(error);
                setLoading(false);
            }
        );
    };

    const columns: TableColumn<Gadget>[] = [
        {
            name: "Id",
            selector: (row) => row._id,
            omit: true,
        },
        {
            name: "Title",
            selector: (row) => row.title,
            format: (row) => (
                <Link to={`/gadgets/${row._id}`}>{row.title}</Link>
            ),
            sortable: true,
            minWidth: "300px",
            allowOverflow: true,
        },
        {
            name: "Variant",
            selector: (row) => row.component,
            sortable: true,
            minWidth: "195px",
        },
        {
            name: "Server",
            selector: (row) =>
                row.server ? row.server.host + ":" + row.server.port : "–",
            sortable: false,
            minWidth: "230px",
        },
        ...(isAllowedTo("gadgets.modify")
            ? [
                  {
                      name: "Created at",
                      selector: (row: Gadget) =>
                          Moment(row.createdAt).format("DD/MM/YYYY"),
                      sortable: true,
                  },
                  {
                      name: "Updated at",
                      selector: (row: Gadget) =>
                          Moment(row.updatedAt).format("DD/MM/YYYY"),
                      sortable: true,
                  },
              ]
            : []),
        ...(isAllowedTo("gadgets.delete") || isAllowedTo("gadgets.modify")
            ? [
                  {
                      name: "Actions",
                      cell: (row: Gadget) => (
                          <div className="row-actions">
                              <Restricted to="gadgets.modify">
                                  <Link to={`/gadgets/${row._id}?edit=1`}>
                                      <Button title="Edit">
                                          <FontAwesomeIcon icon="pencil" />
                                      </Button>
                                  </Link>
                              </Restricted>
                              <Restricted to="gadgets.delete">
                                  <Button
                                      variant="danger"
                                      onClick={() => {
                                          handleDelete(row._id);
                                      }}
                                      title="Delete"
                                  >
                                      <FontAwesomeIcon icon="trash-can" />
                                  </Button>
                              </Restricted>
                          </div>
                      ),
                      minWidth: "125px",
                  },
              ]
            : []),
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
            },
        },
    };

    return (
        <Container>
            <CustomCard
                header="Gadgets"
                infoText="On this page, you can view a list of gadgets. By clicking on the name, you can view details about that gadget. To create a new gadget, simply click on the 'Add gadget' button."
            >
                {message && <Alert variant="info">{message}</Alert>}
                <Restricted to="gadgets.modify">
                    <Link to="/gadgets/new">
                        <Button>Add gadget</Button>
                    </Link>
                </Restricted>
                <DataTable
                    columns={columns}
                    data={response}
                    pagination
                    customStyles={customStyles}
                    striped
                    progressPending={loading}
                />
            </CustomCard>
        </Container>
    );
};

export default GadgetList;
