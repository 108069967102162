import { useEffect, useState } from "react";
import userService from "services/userService";
import { User } from "Types";
import CustomCard from "components/CustomCard";
import { Container, Alert, Button } from "react-bootstrap";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import UserForm from "pages/user/UserForm";
import Restricted from "components/Restricted";
import Moment from "moment";
import InfoPopover from "components/InfoPopover";

const UserOne = () => {
    const [message, setMessage] = useState("");
    const [magicLink, setMagicLink] = useState("");
    const [data, setData] = useState<User | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [searchParams] = useSearchParams();
    const params = useParams();
    const navigate = useNavigate();
    const editParam = searchParams.get("edit");
    const newParam = searchParams.get("new");

    useEffect(() => {
        if (params.id) {
            getUser(params.id);
        } else {
            setEditMode(true);
        }
        // eslint-disable-next-line
    }, [params.id]);

    useEffect(() => {
        if (editParam) {
            setEditMode(true);
        }
    }, [searchParams, editParam]);

    const getUser = (id: string) =>
        userService.getUser(id).then(
            (response) => {
                if (response.data) {
                    response.data.id = response.data._id;
                    setData(response.data);
                }
            },
            (error) => {
                setMessage(error);
            }
        );

    const handleBack = () => {
        if (params.id && !editParam) {
            getUser(params.id);
            setEditMode(false);
        } else {
            if (newParam) {
                navigate("/users");
            } else {
                navigate(-1);
            }
        }
    };

    const getMagicLink = () => {
        if (params.id) {
            userService.getLoginToken(params.id).then(
                (response) => {
                    if (response.data && response.data.token) {
                        setMagicLink(
                            window.location.protocol +
                                "//" +
                                window.location.hostname +
                                (window.location.port
                                    ? ":" + window.location.port
                                    : "") +
                                "/login/" +
                                response.data.token
                        );
                    }
                },
                (error) => {
                    setMessage(error);
                }
            );
        }
    };

    return (
        <Container>
            <CustomCard
                header={
                    editMode
                        ? data
                            ? "Modify user"
                            : "Create new user"
                        : "User"
                }
            >
                {message && <Alert variant="danger">{message}</Alert>}
                {!editMode && (
                    <div>
                        <p>
                            <strong>Username:</strong>{" "}
                            {data ? data.username : ""}
                        </p>
                        <p>
                            <strong>Email:</strong> {data ? data.email : ""}
                        </p>
                        <p>
                            <strong>Role:</strong> {data ? data.role : ""}
                        </p>
                        <p>
                            <strong>Last login:</strong>{" "}
                            {data && data.lastLogin
                                ? Moment(data.lastLogin).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                  )
                                : ""}
                        </p>
                        {magicLink && (
                            <p>
                                <strong>
                                    Magic login link:
                                    <InfoPopover
                                        header="Help"
                                        text="The magic link can be used for login without username and password. It's valid for 10 minutes. Please only share it in a secure channel!"
                                    />
                                </strong>{" "}
                                {magicLink}
                            </p>
                        )}

                        <p>
                            <Button
                                onClick={() => navigate(-1)}
                                variant="secondary"
                            >
                                Back
                            </Button>{" "}
                            <Restricted to="users.modify">
                                <>
                                    <Button onClick={() => setEditMode(true)}>
                                        Modify user
                                    </Button>{" "}
                                    <Button onClick={() => getMagicLink()}>
                                        Generate magic login link
                                    </Button>
                                </>
                            </Restricted>
                        </p>
                    </div>
                )}
                {editMode && (
                    <Restricted to="users.modify">
                        <UserForm data={data} back={handleBack} />
                    </Restricted>
                )}
            </CustomCard>
        </Container>
    );
};

export default UserOne;
