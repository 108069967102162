import { useEffect, useState } from "react";
import eventBus from "common/eventBus";

const DemoTitleBar = () => {
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");

    const onShow = (e: any) => {
        //console.log("onShow: ", e.detail);
        if (e.detail) {
            setTitle(e.detail);
        }
        setShow(true);
    };

    const onHide = () => {
        setShow(false);
        setTitle("");
    };

    useEffect(() => {
        eventBus.on("showDemoTitle", onShow);
        eventBus.on("hideDemoTitle", onHide);

        return () => {
            eventBus.remove("showDemoTitle", onShow);
            eventBus.remove("hideDemoTitle", onHide);
        };
    }, []);

    return <>{show && <div className="demo-title-bar">{title}</div>}</>;
};

export default DemoTitleBar;
