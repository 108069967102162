import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Card, Form, Alert, Button } from "react-bootstrap";
import authService from "services/authService";
import { alert } from "components/MessageDialog";
import { User, LoginForm } from "Types";
import userValidation from "services/userValidation";
import PasswordInput from "components/PasswordInput";

const Login = ({
    currentUser,
    logIn,
}: {
    currentUser: User | undefined;
    logIn: (response: any) => void;
}) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.id) {
            // email confirmation
            authService
                .confirmation(params.id)
                .then((res) => {
                    if (authService.getCurrentUser()) {
                        authService.refreshCurrentUser();
                    }
                    alert(
                        "Successfully confirmed your email address! You can login now."
                    );
                })
                .catch((error) => {
                    alert(error);
                });
        } else if (params.token) {
            // magic login link
            authService.loginWithToken(params.token).then(
                (response) => {
                    navigate("/");
                    logIn(response);
                },
                (error) => {
                    setMessage(error);
                }
            );
        }
        // eslint-disable-next-line
    }, [params.id]);

    useEffect(() => {
        if (currentUser && navigate) {
            navigate("/profile");
        }
    }, [currentUser, navigate]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginForm>({
        resolver: yupResolver(userValidation.loginSchema),
    });

    const handleLogin = ({ username, password }: LoginForm) => {
        setMessage("");
        setLoading(true);

        authService.login(username, password).then(
            (response) => {
                //setRedirect("/profile");
                navigate("/");
                logIn(response);
            },
            (error) => {
                setLoading(false);
                setMessage(error);
            }
        );
    };

    return (
        <Container>
            <Card className="login-form">
                <Card.Body>
                    <div className="login-head">WELCOME TO</div>
                    <img
                        src="/anemos-lab-logo.svg"
                        height="45"
                        className="d-inline-block align-top"
                        alt="Anemos Lab"
                    />
                    <div className="login-text">Please log in</div>
                    <Form onSubmit={handleSubmit(handleLogin)}>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Username / Email"
                                {...register("username")}
                            />
                            {errors.username && (
                                <Alert>{errors.username?.message}</Alert>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <PasswordInput params={register("password")} />
                            {errors.password && (
                                <Alert>{errors.password?.message}</Alert>
                            )}
                        </Form.Group>
                        <Button type="submit" size="lg">
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            ENTER
                        </Button>
                        {message && <Alert variant="info">{message}</Alert>}
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Link to="/password-reset">Forgot your password?</Link>
                </Card.Footer>
            </Card>
        </Container>
    );
};

export default Login;
