import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import eventBus from "common/eventBus";

const MessageDialog = ({ title = "System message" }) => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");

    const handleMessage = (e: any) => {
        if (e.detail) {
            setMessage(e.detail);
        }
        setShow(true);
    };

    const onHide = () => {
        setShow(false);
        setMessage("");
    };

    useEffect(() => {
        eventBus.on("messageDialog", handleMessage);
        eventBus.on("hideMessageDialog", onHide);

        return () => {
            eventBus.remove("messageDialog", handleMessage);
            eventBus.remove("hideMessageDialog", onHide);
        };
    }, []);

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

const alert = (message: String) => {
    eventBus.dispatch("messageDialog", message);
};

const closeAlert = () => {
    eventBus.dispatch("hideMessageDialog");
};

export { MessageDialog, alert, closeAlert };
