import { Alert, Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import demoAclService from "services/demoAclService";
import { Demo, DemoAcl } from "Types";
import DataTable, { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirm } from "components/ConfirmDialog";
import demoService from "services/demoService";
import gadgetAclService from "services/gadgetAclService";

interface demoPermission {
    userId: string;
    demoId: string;
    title: string;
    access: boolean; //granted or denied
}

const UserDemoAclAdmin = ({ userId = "" }: { userId: string }) => {
    const [demos, setDemos] = useState<Demo[]>([]);
    const [demoPermissions, setDemoPermissions] = useState<demoPermission[]>(
        []
    );
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const getDemos = () => {
        demoService.getDemos().then(
            (response) => {
                if (response.data) {
                    setDemos(response.data);
                }
            },
            (error) => {
                console.log(error.message);
            }
        );
    };

    useEffect(() => {
        if (userId) {
            setMessage("");
            getDemos();
            setLoading(true);
        } else {
            setMessage(
                "You can give permissions for demos right after the user is created."
            );
        }
    }, [userId]);

    useEffect(() => {
        if (demos && demos.length > 0) {
            demoAclService.getDemoAcls({ userId: userId }).then(
                (response) => {
                    if (response.data) {
                        let accessDemoIds = response.data.map(
                            (acl: DemoAcl) => {
                                return acl.demo ? acl.demo._id : undefined;
                            }
                        );
                        let arr: demoPermission[] = [];
                        demos.forEach((demo) => {
                            arr = [
                                ...arr,
                                {
                                    userId: userId,
                                    demoId: demo._id,
                                    title: demo.title,
                                    access: accessDemoIds.includes(demo._id),
                                },
                            ];
                        });
                        setDemoPermissions(arr);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                },
                (error) => {
                    console.log(error.message);
                    setLoading(false);
                }
            );
        }
    }, [demos, userId]);

    const handleRemove = async (demoId: string) => {
        if (
            await confirm(
                "Are you sure you wish to remove all the permissions for this demo?"
            )
        ) {
            gadgetAclService
                .removePermissionsFromUserByDemoId(userId, demoId)
                .then(
                    (response) => {
                        setMessage("Successfully removed.");
                        getDemos();
                    },
                    (error) => {
                        setMessage(error);
                    }
                );
        }
    };

    const handleGrant = (demoId: string) => {
        gadgetAclService.grantPermissionsToUserByDemoId(userId, demoId).then(
            (response) => {
                setMessage("Successfully granted.");
                getDemos();
            },
            (error) => {
                setMessage(error);
            }
        );
    };

    const columns: TableColumn<demoPermission>[] = [
        {
            name: "Demo",
            selector: (row) => row.title,
            sortable: true,
            minWidth: "300px",
        },
        {
            name: "Granted",
            cell: (row: demoPermission) => (
                <FontAwesomeIcon
                    icon={row.access ? "check" : "xmark"}
                    style={row.access ? { color: "green" } : { color: "red" }}
                />
            ),
        },
        {
            name: "Action",
            cell: (row: demoPermission) => (
                <div className="row-actions">
                    {!row.access ? (
                        <Button
                            onClick={() => {
                                handleGrant(row.demoId);
                            }}
                            title="Grant all permissions to this demo
                        and the gadgets belongs to it"
                        >
                            <FontAwesomeIcon icon="unlock" /> Grant All
                        </Button>
                    ) : (
                        <Button
                            variant="danger"
                            onClick={() => {
                                handleRemove(row.demoId);
                            }}
                            title="Remove all permissions to this demo
                        and the gadgets belongs to it"
                        >
                            <FontAwesomeIcon icon="lock" /> Remove All
                        </Button>
                    )}
                </div>
            ),
            minWidth: "180px",
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
            },
        },
    };

    return (
        <Form.Group className="mb-3">
            <Form.Label>Permissions for Demos</Form.Label>
            {message && <Alert>{message}</Alert>}
            {demoPermissions.length > 0 && (
                <DataTable
                    columns={columns}
                    data={demoPermissions}
                    customStyles={customStyles}
                    striped
                    progressPending={loading}
                />
            )}
        </Form.Group>
    );
};

export default UserDemoAclAdmin;
