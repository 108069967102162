import { useEffect, useState } from "react";
import serverService from "services/serverService";
import { Server } from "Types";
import CustomCard from "components/CustomCard";
import { Container, Alert, Button } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ServerForm from "pages/server/ServerForm";
import Restricted from "components/Restricted";

const ServerOne = ({ defaultEditMode = false }) => {
    const [message, setMessage] = useState("");
    const [data, setData] = useState<Server | null>(null);
    const [editMode, setEditMode] = useState(defaultEditMode);
    const [searchParams] = useSearchParams();
    const params = useParams();
    const navigate = useNavigate();
    const editParam = searchParams.get("edit");

    useEffect(() => {
        if (params.id) {
            getServer(params.id);
        } else {
            setEditMode(true);
        }
    }, [params.id]);

    useEffect(() => {
        if (editParam) {
            setEditMode(true);
        }
    }, [searchParams, editParam]);

    const getServer = (id: string) =>
        serverService.getServer(id).then(
            (response) => {
                if (response.data) {
                    response.data.id = response.data._id;
                    setData(response.data);
                }
            },
            (error) => {
                setMessage(error);
            }
        );

    const handleEditMode = () => {
        setEditMode(true);
    };

    const handleBack = () => {
        if (params.id && !editParam) {
            getServer(params.id);
            setEditMode(false);
        } else {
            navigate(-1);
        }
    };

    return (
        <Container>
            <CustomCard
                header={
                    editMode
                        ? data
                            ? "Modify server"
                            : "Create new server"
                        : "Server"
                }
            >
                {message && <Alert variant="danger">{message}</Alert>}
                {!editMode && (
                    <div>
                        <p>
                            <strong>Name:</strong> {data ? data.name : ""}
                        </p>
                        <p>
                            <strong>Host:</strong> {data ? data.host : ""}
                        </p>
                        <p>
                            <strong>Port:</strong> {data ? data.port : ""}
                        </p>
                        <p>
                            <strong>Token:</strong> {data ? data.token : ""}
                        </p>
                        <p>
                            <Button
                                onClick={() => navigate(-1)}
                                variant="secondary"
                            >
                                Back
                            </Button>{" "}
                            <Restricted to="servers.modify">
                                <Button onClick={() => handleEditMode()}>
                                    Modify server
                                </Button>
                            </Restricted>
                        </p>
                    </div>
                )}
                {editMode && (
                    <Restricted to="servers.modify">
                        <ServerForm data={data} back={handleBack} />
                    </Restricted>
                )}
            </CustomCard>
        </Container>
    );
};

export default ServerOne;
