import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Restricted from "components/Restricted";
import React, { useState } from "react";
import InfoTooltip from "../../components/InfoTooltip";

const GadgetCard = ({
    children,
    header,
    infoText,
    openConfigPanel = () => {},
    open = true,
}: {
    children: React.ReactNode;
    header?: string;
    infoText?: string;
    openConfigPanel: () => void;
    open?: boolean;
}) => {
    const [isOpen, setIsOpen] = useState(open);

    const toggleCard = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={isOpen ? "gadget-card open" : "gadget-card close"}>
            <div
                className={
                    isOpen
                        ? "card-header btn navbar open"
                        : "card-header btn navbar close"
                }
                onClick={toggleCard}
            >
                <FontAwesomeIcon
                    className="carret-icon"
                    icon={isOpen ? "caret-down" : "caret-right"}
                />
                <p className="mx-auto">{header}</p>
                {infoText && <InfoTooltip text={infoText} placement="bottom" />}
                <Restricted to="gadget.modify">
                    <FontAwesomeIcon
                        className="wrench-icon"
                        icon="wrench"
                        onClick={(e) => {
                            e.stopPropagation();
                            openConfigPanel();
                        }}
                    />
                </Restricted>
            </div>
            <div className={isOpen ? "show" : "collapse"}>
                <div className="card-body">{children}</div>
            </div>
        </div>
    );
};

export default GadgetCard;
