import { useEffect, useState } from "react";
import gadgetService from "services/gadgetService";
import { Gadget } from "Types";
import CustomCard from "components/CustomCard";
import { Container, Alert, Button } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import GadgetForm from "pages/gadget/GadgetForm";
import Restricted from "components/Restricted";
import { Link } from "react-router-dom";
import Popup from "components/Popup";

const GadgetOne = ({
    defaultEditMode = false,
    inModal = false,
    gadgetId,
    isOpen = false,
    toggle = () => {},
}: {
    defaultEditMode?: boolean;
    inModal?: boolean;
    gadgetId?: string | undefined;
    isOpen?: boolean;
    toggle?: () => void;
}) => {
    const [message, setMessage] = useState("");
    const [data, setData] = useState<Gadget | null>(null);
    const [editMode, setEditMode] = useState(defaultEditMode);
    const [searchParams] = useSearchParams();
    const params = useParams();
    const navigate = useNavigate();
    const editParam = searchParams.get("edit");

    useEffect(() => {
        if (gadgetId) {
            getGadget(gadgetId);
        } else if (params.id) {
            getGadget(params.id);
        } else {
            setEditMode(true);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isOpen && gadgetId) {
            getGadget(gadgetId);
        }
        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        if (editParam) {
            setEditMode(true);
        }
    }, [searchParams, editParam]);

    const getGadget = (id: string) => {
        //console.log(id);
        gadgetService.getGadget(id).then(
            (response) => {
                if (response.data) {
                    response.data.id = response.data._id;
                    setData(response.data);
                }
            },
            (error) => {
                setMessage(error);
            }
        );
    };

    const handleEditMode = () => {
        setEditMode(true);
    };

    const handleBack = () => {
        if (params.id && !editParam) {
            getGadget(params.id);
            setEditMode(false);
        } else {
            navigate(-1);
        }
    };

    const content = (
        <>
            {message && <Alert variant="danger">{message}</Alert>}
            {!editMode && (
                <div>
                    <p>
                        <strong>Title:</strong> {data ? data.title : ""}
                    </p>
                    <p>
                        <strong>Description:</strong> {data ? data.desc : ""}
                    </p>
                    <p>
                        <strong>Variant:</strong> {data ? data.component : ""}
                    </p>
                    <p>
                        <strong>Server:</strong>{" "}
                        {data && data.server && (
                            <Link to={`/servers/${data.server._id}`}>
                                {data.server.name +
                                    " [" +
                                    data.server.host +
                                    ":" +
                                    data.server.port +
                                    "]"}
                            </Link>
                        )}
                    </p>
                    <p>
                        <strong>Parameters:</strong>{" "}
                        <pre>
                            {data && data.params
                                ? JSON.stringify(
                                      JSON.parse(data.params),
                                      null,
                                      2
                                  )
                                : ""}
                        </pre>
                    </p>
                    <p>
                        <strong>Open status:</strong>{" "}
                        {data
                            ? data.open
                                ? "Open by default"
                                : "Closed by default"
                            : ""}
                    </p>
                    <p>
                        <Button
                            onClick={() => navigate(-1)}
                            variant="secondary"
                        >
                            Back
                        </Button>{" "}
                        <Restricted to="gadgets.modify">
                            <Button onClick={() => handleEditMode()}>
                                Modify gadget
                            </Button>
                        </Restricted>
                    </p>
                </div>
            )}
            {editMode && (
                <Restricted to="gadgets.modify">
                    <GadgetForm
                        data={data}
                        back={!inModal ? handleBack : undefined}
                    />
                </Restricted>
            )}
        </>
    );

    if (inModal) {
        return (
            <Popup header="Modify gadget" isOpen={isOpen} toggle={toggle}>
                {content}
            </Popup>
        );
    }
    return (
        <Container>
            <CustomCard
                header={
                    editMode
                        ? data
                            ? "Modify gadget"
                            : "Create new gadget"
                        : "Gadget"
                }
            >
                {content}
            </CustomCard>
        </Container>
    );
};

export default GadgetOne;
