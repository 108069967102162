import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

const Popup = ({
    children,
    header,
    isOpen,
    toggle,
    enforceFocus = true,
}: {
    children: React.ReactNode;
    header?: string;
    isOpen: boolean;
    toggle: () => void;
    enforceFocus?: boolean;
}) => {
    const [show, setShow] = useState(isOpen);

    const close = () => {
        toggle();
    };

    useEffect(() => {
        if (isOpen) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [isOpen]);

    return (
        <Modal
            show={show}
            onHide={close}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            enforceFocus={enforceFocus}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {header}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button onClick={close}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Popup;
