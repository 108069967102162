export type GadgetProps = {
    [key: string]: { type: string; title: string };
};

export const gadgetProps: GadgetProps = {
    PositionGraph: { type: "position", title: "Position Graph" },
    PositionTable: { type: "position", title: "Position Table" },
    ImageViewer: { type: "image", title: "Image Viewer" },
    StageControl: { type: "control", title: "Stage Control" },
    StageControlSimple: { type: "control", title: "Stage Control Simple" },
    Alert: { type: "internal", title: "Alert" },
    EditableContent: { type: "internal", title: "Editable Content" },
};
