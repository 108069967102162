import CustomCard from "components/CustomCard";
import Restricted from "components/Restricted";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { User } from "Types";

const Profile = ({ currentUser }: { currentUser: User | undefined }) => {
    return (
        <Container>
            <Restricted to="me">
                <CustomCard
                    header="Profile"
                    infoText="This is your profile where you can manage your personal data. You can modify your email and password. We store your data securely and never send spam to your email address."
                >
                    <div>
                        <p>
                            <strong>Username:</strong>{" "}
                            {currentUser && currentUser.username}
                        </p>
                        <p>
                            <strong>Email:</strong>{" "}
                            {currentUser && currentUser.email}
                        </p>
                        <p>
                            <strong>Role:</strong>{" "}
                            {currentUser && currentUser.role}
                        </p>
                        <Restricted to="me">
                            <>
                                <Link to="/modify-email">
                                    <Button>Modify email</Button>
                                </Link>{" "}
                                <Link to="/modify-password">
                                    <Button>Modify password</Button>
                                </Link>
                            </>
                        </Restricted>
                    </div>
                </CustomCard>
            </Restricted>
        </Container>
    );
};

export default Profile;
