import api from "services/api";
import authHeader from "services/authHeader";

const API_URL = "login-attempts/";

const getLoginAttempts = (params = {}) => {
    return api.get(API_URL, { headers: authHeader(), params: params });
};

const getBannedClients = () => {
    return api.get(API_URL, {
        headers: authHeader(),
        params: { isBanned: true },
    });
};

const deleteLoginAttempt = (id: string) => {
    return api.delete(API_URL + id, { headers: authHeader() });
};

const loginAttemptService = {
    getLoginAttempts,
    getBannedClients,
    deleteLoginAttempt,
};

export default loginAttemptService;
