import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { User } from "Types";
//import authService from "../services/authService";
import CustomCard from "components/CustomCard";

const OpenDoor = ({ currentUser }: { currentUser: User | undefined }) => {
    //const [loading, setLoading] = useState(false);
    //const [message, setMessage] = useState("");
    //const [token, setToken] = useState("");
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (!params.id || params.id !== "enter") {
            navigate("/login");
        }

        /*const getToken = () => {
            authService.getGuestToken().then(
                (response) => {
                    if (response.data && response.data.token) {
                        setToken(response.data.token);
                    }
                },
                (error) => {
                    //setMessage(error);
                    setMessage(
                        "Sorry, there is some error with the guest entering process. Please try later!"
                    );
                }
            );
        };
        getToken();

        // get guest token in every 10mins
        let interval = setInterval(() => getToken(), 550000);

        return () => clearInterval(interval);*/
    }, [navigate, params.id]);

    useEffect(() => {
        if (currentUser && navigate) {
            navigate("/");
        }
    }, [currentUser, navigate]);

    /*const handleLogin = () => {
        //console.log("/login/" + token);
        setLoading(true);
        navigate("/login/" + token);
    };*/

    /*<Card className="enter-form">
                <Card.Body>
                    <div className="login-text">Enter as a guest:</div>
                    <Button onClick={handleLogin} size="lg">
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        ENTER
                    </Button>
                    {message && <Alert variant="info">{message}</Alert>}
                </Card.Body>
                <Card.Footer>
                    or <Link to="/login">Log in</Link>
                    <p>
                        <br />
                        If you have any questions:{" "}
                        <a
                            href="https://anemos.co.uk/contact/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Contact us
                        </a>
                    </p>
                </Card.Footer>
            </Card>*/

    return (
        <Container>
            <CustomCard>
                <Row className="justify-content-center">
                    <Col lg="4" sm="3" xs="1"></Col>
                    <Col lg="4" sm="6" xs="10">
                        <img
                            src="/anemos-logo-inline.svg"
                            alt="Anemos logo"
                            className="nano-scale-head-logos"
                        />
                        <p className="nano-scale-head-text">
                            in association with
                        </p>
                        <img
                            src="/npl-logo.svg"
                            alt="NPL logo"
                            className="nano-scale-head-logos"
                        />
                    </Col>
                    <Col lg="4" sm="3" xs="1"></Col>
                </Row>
                <Row>
                    <Col className="nano-scale-content">
                        <h1>
                            Welcome to colleagues at NanoScale 2023, Helsinki!
                        </h1>
                        <Row className="justify-content-center">
                            <Col lg="4" sm="3" xs="2"></Col>
                            <Col lg="4" sm="6" xs="8">
                                <img
                                    src="/maps-full-logo-linear.svg"
                                    alt="MAPS logo"
                                    className="maps-logo"
                                />
                            </Col>
                            <Col lg="4" sm="3" xs="2"></Col>
                        </Row>
                        <p>
                            This page provided a live and open web demo of MAPS
                            to support Edward Heaps and Andrew Yaccot's
                            presentation. The content of the demo visualizes the
                            resolution of the MAPS position sensor in a
                            realistic environment.
                        </p>
                        <p>
                            The demo is no longer open. Please{" "}
                            <a
                                href="https://anemos.co.uk/contact/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                contact us
                            </a>{" "}
                            to arrange an interactive demo.
                        </p>
                    </Col>
                </Row>
            </CustomCard>
        </Container>
    );
};

export default OpenDoor;
