import { Alert } from "react-bootstrap";

const AlertGadget = ({
    message,
    variant,
}: {
    message?: string;
    variant?: string;
}) => {
    return <div>{message && <Alert variant={variant}>{message}</Alert>}</div>;
};

export default AlertGadget;
