import Container from "react-bootstrap/Container";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Demo, User } from "Types";
import eventBus from "common/eventBus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Restricted from "components/Restricted";
import { Link } from "react-router-dom";
import demoService from "services/demoService";
import { useContext, useEffect, useState } from "react";
import PermissionContext from "common/PermissionContext";
import DemoTitleBar from "pages/demo/DemoTitleBar";

const NavbarHeader = ({ currentUser }: { currentUser: User | undefined }) => {
    const [demos, setDemos] = useState<Demo[]>();
    const { isAllowedTo } = useContext(PermissionContext);

    const getDemos = () => {
        demoService.getDemos().then(
            (response) => {
                if (response.data) {
                    //console.log("OWN DEMOS:", response.data);
                    setDemos(response.data);
                }
            },
            (error) => {}
        );
    };

    useEffect(() => {
        if (
            currentUser &&
            (isAllowedTo("demos.view") || isAllowedTo("demos.view.own"))
        ) {
            getDemos();
        }
    }, [currentUser, isAllowedTo]);

    return (
        <Navbar bg="white" expand="lg">
            <Container>
                <Navbar.Brand>
                    <Link to="/">
                        <img
                            src="/anemos-lab-logo.svg"
                            className="d-inline-block align-top logo-img"
                            alt="Anemos Lab"
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Restricted to="users.view">
                            <NavDropdown
                                title={
                                    <>
                                        Admin{" "}
                                        <FontAwesomeIcon icon="screwdriver-wrench" />
                                    </>
                                }
                                id="basic-nav-dropdown"
                            >
                                <Restricted to="users.view">
                                    <NavDropdown.Item as={Link} to="/users">
                                        Users <FontAwesomeIcon icon="users" />
                                    </NavDropdown.Item>
                                </Restricted>
                                <Restricted to="servers.view">
                                    <NavDropdown.Item as={Link} to="/servers">
                                        Servers{" "}
                                        <FontAwesomeIcon icon="server" />
                                    </NavDropdown.Item>
                                </Restricted>
                                <Restricted to="gadgets.view">
                                    <NavDropdown.Item as={Link} to="/gadgets">
                                        Gadgets{" "}
                                        <FontAwesomeIcon icon="window-restore" />
                                    </NavDropdown.Item>
                                </Restricted>
                                <Restricted to="demos.view">
                                    <NavDropdown.Item as={Link} to="/demos">
                                        Demos{" "}
                                        <FontAwesomeIcon icon="person-chalkboard" />
                                    </NavDropdown.Item>
                                </Restricted>
                                <Restricted to="loginAttempts.view">
                                    <NavDropdown.Item as={Link} to="/banned">
                                        Ban list <FontAwesomeIcon icon="ban" />
                                    </NavDropdown.Item>
                                </Restricted>
                                <Restricted to="logs.view">
                                    <NavDropdown.Item as={Link} to="/logins">
                                        Logins{" "}
                                        <FontAwesomeIcon icon="house-laptop" />
                                    </NavDropdown.Item>
                                </Restricted>
                            </NavDropdown>
                        </Restricted>
                        <Restricted to="demos.view">
                            <NavDropdown
                                title={
                                    <>
                                        Demos <FontAwesomeIcon icon="gears" />
                                    </>
                                }
                                id="basic-nav-dropdown"
                            >
                                <>
                                    {demos &&
                                        demos.map((d) => (
                                            <NavDropdown.Item
                                                as={Link}
                                                to={`/demo/${d._id}`}
                                                key={d._id}
                                            >
                                                {d.title}
                                            </NavDropdown.Item>
                                        ))}
                                </>
                            </NavDropdown>
                        </Restricted>
                    </Nav>
                    <DemoTitleBar />
                    {currentUser && (
                        <Nav className="ms-auto">
                            <Restricted
                                to="me"
                                otherwise={
                                    <Nav.Item className="no-link-nav-item">
                                        <FontAwesomeIcon icon="user" />{" "}
                                        {currentUser?.username}
                                    </Nav.Item>
                                }
                            >
                                <LinkContainer to="/profile">
                                    <Nav.Link>
                                        <FontAwesomeIcon icon="user" />{" "}
                                        {currentUser?.username}
                                    </Nav.Link>
                                </LinkContainer>
                            </Restricted>
                            <LinkContainer
                                to="/"
                                onClick={() => eventBus.dispatch("logout")}
                            >
                                <Nav.Link>
                                    <FontAwesomeIcon icon="right-from-bracket" />
                                </Nav.Link>
                            </LinkContainer>
                        </Nav>
                    )}
                    {!currentUser && (
                        <Nav>
                            <LinkContainer to="/login">
                                <Nav.Link>
                                    Log in{" "}
                                    <FontAwesomeIcon icon="right-to-bracket" />
                                </Nav.Link>
                            </LinkContainer>
                        </Nav>
                    )}
                </Navbar.Collapse>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </Container>
        </Navbar>
    );
};

export default NavbarHeader;
