import api from "services/api";
import { Server } from "Types";
import authHeader from "services/authHeader";

const API_URL = "servers/";

const getServers = () => {
    return api.get(API_URL, { headers: authHeader() });
};

const getServer = (id: string) => {
    return api.get(API_URL + id, { headers: authHeader() });
};

const createServer = (server: Server) => {
    return api.post(API_URL, server, { headers: authHeader() });
};

const updateServer = (id: string, server: Server) => {
    return api.put(API_URL + id, server, { headers: authHeader() });
};

const deleteServer = (id: string) => {
    return api.delete(API_URL + id, { headers: authHeader() });
};

const serverService = {
    getServers,
    getServer,
    createServer,
    updateServer,
    deleteServer,
};

export default serverService;
