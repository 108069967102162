import api from "services/api";
import authHeader from "services/authHeader";

const API_URL = "logs/";

const getLogs = (params = {}) => {
    return api.get(API_URL, { headers: authHeader(), params: params });
};

const logService = {
    getLogs,
};

export default logService;
